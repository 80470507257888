import type { CSSProperties } from 'react'
import type { NonEmptyArray } from './utils/typescript'

const fontWeight = {
  regular: 400,
  medium: 500,
} as const

const lineHeight = {
  heading: 1.15,
  body: 1.35,
  medium: 1.25,
  small: 1,
} as const

export const typography = {
  title1: {
    lineHeight: lineHeight.heading,
    fontWeight: fontWeight.medium,
    fontSize: [35, 40, 56],
  },
  title2: {
    lineHeight: lineHeight.heading,
    fontWeight: fontWeight.medium,
    fontSize: [27, 35, 40],
  },
  title3: {
    lineHeight: lineHeight.heading,
    fontWeight: fontWeight.medium,
    fontSize: [18, 21, 23],
  },
  title4: {
    lineHeight: lineHeight.heading,
    fontWeight: fontWeight.medium,
    fontSize: [14, 18, 21],
  },
  body1: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.regular,
    fontSize: [18, 21, 23],
  },
  body1Strong: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.medium,
    fontSize: [18, 21, 23],
  },
  body2: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.regular,
    fontSize: [16, 18, 21],
  },
  body2Strong: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.medium,
    fontSize: [16, 18, 21],
  },
  body3: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.regular,
    fontSize: [14, 16, 18],
  },
  body3Strong: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.medium,
    fontSize: [14, 16, 18],
  },
  body4: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.regular,
    fontSize: [12, 14, 16],
  },
  body4Strong: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.medium,
    fontSize: [12, 14, 16],
  },
  smallText: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.regular,
    fontSize: [10, 12, 14],
  },
  CTA: {
    lineHeight: lineHeight.medium,
    fontWeight: fontWeight.medium,
    fontSize: [16, 16, 18],
  },
  ctaSmall: {
    lineHeight: lineHeight.medium,
    fontWeight: fontWeight.medium,
    fontSize: 14,
  },
  label: {
    lineHeight: lineHeight.small,
    fontWeight: fontWeight.medium,
    textTransform: 'uppercase',
    fontSize: [10, 10, 12],
  },
  meta: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.regular,
    fontSize: [14, 16, 18],
  },
  metaStrong: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.medium,
    fontSize: [14, 16, 18],
  },
  cardDetail: {
    lineHeight: lineHeight.medium,
    fontWeight: fontWeight.regular,
    fontSize: [14, 14, 16],
  },
  cardDetailStrong: {
    lineHeight: lineHeight.medium,
    fontWeight: fontWeight.medium,
    fontSize: [14, 14, 16],
  },
  cardDetailMeta: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.regular,
    fontSize: [12, 12, 14],
  },
  clipDetail: {
    lineHeight: lineHeight.medium,
    fontWeight: fontWeight.regular,
    fontSize: [12, 14, 18],
  },
  clipDetailStrong: {
    lineHeight: lineHeight.medium,
    fontWeight: fontWeight.medium,
    fontSize: [12, 14, 18],
  },
  category: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.medium,
    fontSize: [16, 18, 28],
  },
  nav: {
    lineHeight: lineHeight.small,
    fontWeight: fontWeight.regular,
    fontSize: [14, 14, 16],
  },
  tab: {
    lineHeight: lineHeight.small,
    fontWeight: fontWeight.regular,
    fontSize: [16, 18],
  },
  tabActive: {
    lineHeight: lineHeight.small,
    fontWeight: fontWeight.medium,
    fontSize: [16, 18],
  },
  packageCardTitle: {
    lineHeight: lineHeight.medium,
    fontWeight: fontWeight.regular,
    fontSize: [12, 14, 18],
  },
  packageCardBody: {
    lineHeight: lineHeight.medium,
    fontWeight: fontWeight.regular,
    fontSize: [14, 14, 16],
  },
  info: {
    lineHeight: lineHeight.medium,
    fontWeight: fontWeight.regular,
    fontSize: [16, 16, 18],
  },
  infoStrong: {
    lineHeight: lineHeight.medium,
    fontWeight: fontWeight.medium,
    fontSize: [16, 16, 18],
  },
  tableauMeta: {
    lineHeight: lineHeight.medium,
    fontWeight: fontWeight.medium,
    textTransform: 'uppercase',
    fontSize: 12,
  },
  seasonDefault: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.medium,
    fontSize: 18,
  },
  seasonSelected: {
    lineHeight: lineHeight.body,
    fontWeight: fontWeight.medium,
    fontSize: 23,
  },
} satisfies Record<string, CSSProperties | { fontSize: NonEmptyArray<number> }>
