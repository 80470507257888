import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import * as ChatBotService from '@nordic-web/rest-codegen/generated/auth'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { brandConfig } from '@/config/brand'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { paths } from '@/helpers/paths'
import { useGlobalEventListener } from '@/hooks/use-global-events'

const pathsWithChat = [paths.faq.urlString(), paths.packages.urlString()]

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    kindlyChat: LegitimateAny
    kindlyOptions: LegitimateAny
  }
}
const script = `https://chat.kindlycdn.com/kindly-chat.js`

export const KindlyChat = () => {
  const router = useRouter()
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false)
  const [isKindlyLoaded, setIsKindlyLoaded] = useState(false)

  const { isLoggedIn, accessToken, hasValidAccessToken } = useAuthState()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (isLoggedIn && !hasValidAccessToken) return
    window.kindlyOptions = {
      getAuthToken: async (chatId: string) => {
        if (!accessToken) return

        const response = await ChatBotService.authenticationWebChatBotControllerKindlyChatBotToken({
          authorization: formatAuthorizationHeader(accessToken),
          chatId,
          clientId: brandConfig.clientName,
        })

        return response.chat_bot_token
      },
      bubbleHidden: true,
      customFont: 'FyranSans, Arial, Helvetica, sans-serif',
      context: {
        accessToken,
      },
    }
    // Without this the options aren't populated before we load the script, which mean the auth call does not happen on the first opening of the chat
    setTimeout(() => setIsOptionsLoaded(true), 1)
  }, [accessToken, isLoggedIn, hasValidAccessToken])

  const shouldShowChat = pathsWithChat.some((path) => router.asPath.startsWith(path))
  useEffect(() => {
    if (!window?.kindlyChat || !isKindlyLoaded) return

    if (shouldShowChat) {
      window.kindlyChat.showBubble()
    } else {
      window.kindlyChat.hideBubble()
      window.kindlyChat.closeChat()
    }
  }, [shouldShowChat, isKindlyLoaded])

  async function closeCase() {
    const context = await window.kindlyChat.getContext()
    const case_id = context.salesforce_case_id || ''

    if (case_id.length > 0) {
      fetch('https://europe-west1-cust-mtv.cloudfunctions.net/closeCaseOnEnd', {
        method: 'POST',
        mode: 'no-cors',
        body: JSON.stringify({
          case_id: case_id,
        }),
      })
    }
  }

  useGlobalEventListener('kindly:load', () => {
    setIsKindlyLoaded(true)
  })

  useGlobalEventListener('kindly:ui', async (type: LegitimateAny) => {
    if (type?.detail?.type === 'end-chat') closeCase()
  })

  useGlobalEventListener('kindly:message', async () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      closeCase()
    }, 900000) // 15 minutes
  })

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  if (!isOptionsLoaded) return null

  return <Script id="kindly-chat" src={script} data-bot-key={brandConfig.kindlyDataBotKey} data-shadow-dom defer />
}
