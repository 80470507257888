import React, { type PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Stack, TypographyText } from '@nordic-web/ui-components'
import { desktop, tablet } from '@nordic-web/ui-styles'
import { useSubscription } from '@/features/checkout/hooks/use-subscription'
import { PackageHighlight } from '@/features/packages/components/package-highlight'
import type { PriceOverrideProps } from '@/features/packages/components/package-price'
import { PackagePrice } from '@/features/packages/components/package-price'
import type { PackageWithState } from '@/features/packages/hooks/use-package-list'
import { paths } from '@/helpers/paths'
import { useTierChangeEnabled } from '@/hooks/use-tier-change-enabled'
import { PackageLabel } from './package-label'

type PackageCardProps = {
  packageData: PackageWithState
  size?: 'md' | 'sm'
  fullWidth?: boolean
  priceOverride?: PriceOverrideProps
}

type SizedPackageCardProps = {
  size?: 'md' | 'sm'
  isActive?: boolean
  fullWidth?: boolean
}

const PackageCardContainer = styled.div<SizedPackageCardProps>(({ fullWidth, size, isActive = false, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(4),
  paddingTop: size === 'sm' || size === 'md' ? theme.space(3) : theme.space(4),
  paddingBottom: theme.space(3),
  paddingLeft: size !== 'sm' ? theme.space(4) : undefined,
  paddingRight: size !== 'sm' ? theme.space(4) : undefined,
  background: theme.color.surface.white10,
  border: isActive ? `1px solid ${theme.color.surface.white25}` : undefined,
  borderRadius: theme.radii.border_radius,
  height: '100%',
  cursor: size === 'sm' ? 'pointer' : 'auto',
  ...(!fullWidth && {
    width: size === 'md' ? '280px' : size === 'sm' ? 'calc(90vw/2)' : '280px',
    [tablet]: {
      width: size === 'md' ? '340px' : size === 'sm' ? '210px' : '280px',
    },
  }),
  [desktop]: {
    paddingTop: theme.space(4),
    paddingBottom: theme.space(4),
    ...(!fullWidth && {
      width: size === 'md' ? '340px' : size === 'sm' ? '210px' : 'fit-content',
      maxWidth: size === 'md' ? '340px' : size === 'sm' ? '210px' : '280px',
    }),
  },
}))

const PackagePitchComponent = styled(TypographyText)<SizedPackageCardProps>(({ size, theme }) => ({
  ...(size === 'sm' ? { minHeight: '80px' } : {}),
  textAlign: 'center',
  paddingInline: theme.space(3),
  height: 'auto',
  [tablet]: {
    minHeight: size === 'sm' ? 120 : 56,
  },
}))

export const PackageCardCommon = ({
  packageData,
  size,
  fullWidth,
  priceOverride,
  children,
}: PropsWithChildren<PackageCardProps>) => {
  const {
    voucher: { data: voucherData },
  } = useSubscription()
  const router = useRouter()
  const isTierChangeEnabled = useTierChangeEnabled()

  const isPackageActive = isTierChangeEnabled && packageData.isActive

  const isInPackageList = size !== 'md'

  const handleClickOnCard = () => {
    if (size === 'sm' && !packageData.isFree) {
      router.push(paths.packages.urlString({ tierId: packageData.tierId }))
    }
  }

  return (
    <PackageCardContainer size={size} isActive={isPackageActive} fullWidth={fullWidth}>
      <Stack nwGap={4} nwAlignItems="center" onClick={handleClickOnCard}>
        <PackageLabel packageName={packageData.title} isPackageCard />
        <Stack nwGap={1} nwAlignItems="center">
          <PackagePrice
            packageData={packageData}
            voucherData={voucherData}
            showBindingPrice={size !== 'sm'}
            showVoucherPrice={!isInPackageList}
            showFromPrice={size === 'sm'}
            priceOverride={priceOverride}
          />
          <PackageHighlight highlight={packageData.highlight} />
        </Stack>
        <PackagePitchComponent nwVariant="cardDetail" nwColor="secondary" size={size}>
          {packageData.pitch}
        </PackagePitchComponent>
      </Stack>
      {children}
    </PackageCardContainer>
  )
}
