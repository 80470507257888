import { useMemo } from 'react'
import styled from 'styled-components'
import { Button } from '../../button/button/button'
import { Stack } from '../../layout/stack-redux/stack'

function getDotSize(activeIndex: number, itemIndex: number, itemCount: number) {
  if (activeIndex < 0 || activeIndex > itemCount - 1) {
    return 'small'
  }

  if (activeIndex === itemIndex) {
    return 'big'
  }

  if (Math.abs(activeIndex - itemIndex) === 1) {
    return 'medium'
  }

  return 'small'
}

const DotsStack = styled(Stack)<{ $count: number }>(({ theme, $count }) => ({
  height: theme.space(6),
  minWidth: `calc(${$count} * ${theme.space(3)} + ${theme.space(6)})`,
}))

const Dot = styled.div<{ $size: ReturnType<typeof getDotSize>; $clickable: boolean }>(
  ({ theme, $size, $clickable }) => ({
    transition: 'all 250ms',
    ...($clickable ? { cursor: 'pointer' } : {}),
    ...($size === 'big'
      ? {
          width: theme.space(4),
          height: theme.space(2),
          borderRadius: theme.space(2),
          marginLeft: theme.space(1),
          marginRight: theme.space(1),
          backgroundColor: theme.color.icon.primary,
        }
      : $size === 'medium'
        ? {
            width: theme.space(2),
            height: theme.space(2),
            borderRadius: theme.space(1),
            marginLeft: theme.space(1),
            marginRight: theme.space(1),
            backgroundColor: theme.color.icon.tertiary,
          }
        : {
            width: theme.space(1),
            height: theme.space(1),
            borderRadius: theme.space(1),
            marginLeft: theme.space(1),
            marginRight: theme.space(1),
            backgroundColor: theme.color.icon.tertiary,
          }),
  })
)

type NavigationDotsProps = {
  count: number
  activeIndex: number
  onNext?: () => void
  onPrevious?: () => void
  hideButtons?: boolean
  onDotClick?: (index: number) => void
}

export const NavigationDots = ({
  count,
  activeIndex,
  onNext,
  onPrevious,
  hideButtons = false,
  onDotClick,
}: NavigationDotsProps) => {
  const dots = useMemo(() => Array.from({ length: count }, (_, i) => i), [count])

  return (
    <Stack nwDirection="row" nwAlignItems="center">
      {!hideButtons && <Button nwSize="small" nwVariant="ghost" nwIconFirst="chevron-left" onClick={onPrevious} />}
      <DotsStack nwDirection="row" nwAlignItems="center" nwJustifyContent="center" $count={count}>
        {dots.map((index) => (
          <Dot
            key={index}
            $size={getDotSize(activeIndex, index, count)}
            $clickable={typeof onDotClick === 'function'}
            onClick={() => onDotClick?.(index)}
          />
        ))}
      </DotsStack>
      {!hideButtons && <Button nwSize="small" nwVariant="ghost" nwIconFirst="chevron-right" onClick={onNext} />}
    </Stack>
  )
}
