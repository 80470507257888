import { useEffect } from 'react'
import { useTranslations } from 'next-intl'
import { type NextRouter, useRouter } from 'next/router'
import { useUserLazyQuery } from '@nordic-web/gql'
import { NotificationSnackbarDuration, useNotificationSnackbar } from '@nordic-web/ui-components'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { useAccountApi } from '@/features/auth/hooks/use-account-api'
import { isOnAnyProfilePage } from '@/features/profiles/helpers/is-on-any-profile-page'
import { useRenoNotification } from '@/features/reno/hooks/use-reno-notification'
import { paths } from '@/helpers/paths'

export const shouldNotAutoRedirectToProfilePage = (router: NextRouter) => {
  return paths.checkout.isActive(router) || paths.faq.isActive(router) || paths.settings.isActive(router)
}

export function OnProfileChange() {
  const t = useTranslations()
  const router = useRouter()
  const { showSnackbarMessage } = useNotificationSnackbar()
  const [getUser] = useUserLazyQuery()
  const { profileId, changeProfile } = useAuthState()
  const { user } = useAccountApi()
  const isUserLoggedIn = user.isFetched
  const isOnProfilePage = isOnAnyProfilePage(router)

  const redirectToSelectProfile = async () => {
    const shouldNotRedirect = shouldNotAutoRedirectToProfilePage(router)
    if (shouldNotRedirect) {
      changeProfile({
        profileId: 'default',
        isChild: false,
        reloadPage: false,
      })
    } else {
      await router.push(paths.selectProfile.urlString({ return: router.asPath }))
    }
  }

  useEffect(() => {
    // Redirect to select profile if user has never selected a profile
    if (!profileId && isUserLoggedIn && !isOnProfilePage) {
      redirectToSelectProfile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, isUserLoggedIn, isOnProfilePage])

  async function onProfileChange() {
    const userData = await getUser({ fetchPolicy: 'network-only', ssr: false })
    if (userData?.data?.user.activeProfile === null && !paths.selectProfile.isActive(router)) {
      await redirectToSelectProfile()
      showSnackbarMessage({
        text: t('profiles__error_profile_deleted'),
        duration: NotificationSnackbarDuration.LONG,
        type: 'negative',
      })
    }
  }

  useRenoNotification('PROFILES_CHANGED', () => {
    onProfileChange()
  })

  return null
}
