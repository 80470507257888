import { forwardRef, useId, useImperativeHandle, useRef, useState } from 'react'
import type { ChangeEvent, ComponentPropsWithoutRef, RefObject } from 'react'
import type { UseFormRegisterReturn } from 'react-hook-form'
import styled from 'styled-components'
import { tablet } from '@nordic-web/ui-styles'
import { A11yVisuallyHidden } from '../../a11y/a11y-visually-hidden/a11y-visually-hidden'
import { Icon } from '../../icon/icon/icon'
import { TypographyText } from '../../typography/typography-text/typography-text'

type InputProps = ComponentPropsWithoutRef<'input'>

export type FormInputSearchProps = {
  nwLabel: string
  nwOnDirty?: () => void
  nwClearButtonLabel: string
  nwInputRef?: RefObject<HTMLInputElement>
  /**
   * Minimum requirement is to pass the name to the register function from react-hook-form: `validators={register('input-name')}`
   */
  validators: UseFormRegisterReturn
} & Omit<InputProps, 'type' | 'name'> &
  Required<Pick<InputProps, 'placeholder'>>

type InputHandle = {
  focus: () => void
}
/**
 * Large search input with clear button. This is used in the main search on the web.
 */
const FormInputSearch = forwardRef<InputHandle, FormInputSearchProps>(
  ({ nwLabel, nwOnDirty, nwClearButtonLabel, validators, ...forwardProps }, ref) => {
    const randomId = useId()
    const id = forwardProps.id ?? `form-input-search-${randomId}`
    const inputRef = useRef<HTMLInputElement>(null)
    const [isDirty, setIsDirty] = useState(false)

    useImperativeHandle(ref, () => {
      return {
        focus: () => {
          inputRef.current?.focus()
        },
      }
    }, [])

    const clearInput = () => {
      if (!inputRef.current) return
      inputRef.current.value = ''
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }))
      inputRef.current.focus()
    }

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
      if (!isDirty) {
        setIsDirty(true)
        nwOnDirty?.()
      }

      forwardProps.onInput?.(e)
    }

    return (
      <Wrapper>
        <Label htmlFor={id}>
          <StyledSearchIcon nwVariant="search" aria-hidden nwUnset />
          <A11yVisuallyHidden>{nwLabel}</A11yVisuallyHidden>
        </Label>
        <Input
          as="input"
          nwVariant="body1"
          autoCorrect="off"
          autoCapitalize="off"
          autoComplete="off"
          spellCheck="false"
          {...validators}
          {...forwardProps}
          id={id}
          ref={inputRef}
          onInput={handleInput}
          type="search"
        />
        <ClearButton type="button" onClick={clearInput} aria-label={nwClearButtonLabel}>
          <StyledClearIcon nwVariant="close-x-filled" nwSize="small" aria-hidden />
        </ClearButton>
      </Wrapper>
    )
  }
)

FormInputSearch.displayName = 'FormInputSearch'

export { FormInputSearch }

const StyledClearIcon = styled(Icon)({
  flex: 1,
})

const Wrapper = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.space(2),
  width: '100%',
  alignItems: 'center',
}))

const StyledSearchIcon = styled(Icon)(({ theme }) => ({
  display: 'block',
  color: theme.color.text.primary,
  width: theme.size.icon.smallMedium,
  height: theme.size.icon.smallMedium,
  flexShrink: 0,
  [tablet]: {
    width: theme.size.icon.large,
    height: theme.size.icon.large,
  },
}))

const Label = styled.label({
  gridRow: 1,
  gridColumn: 1,
  cursor: 'pointer',
})

const Input = styled(TypographyText)(({ theme }) => ({
  gridRow: 1,
  gridColumn: 2,
  appearance: 'none',
  border: 'none',
  background: 'transparent',
  caretColor: theme.color.text.primary,
  color: theme.color.text.primary,
  fontFamily: theme.fonts.body,
  outline: 'none',
  paddingInlineEnd: theme.size.icon.medium,
  minHeight: theme.size.icon.small,
  [`&::placeholder`]: {
    color: theme.color.text.tertiary,
  },
  [`&::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration`]: {
    display: 'none',
  },
}))

const ClearButton = styled.button(({ theme }) => ({
  gridRow: 1,
  gridColumn: 2,
  justifySelf: 'end',
  appearance: 'none',
  background: 'none',
  border: 'none',
  margin: 0,
  cursor: 'pointer',
  display: 'flex',
  borderRadius: '50%',
  color: theme.color.text.tertiary,
  padding: 2,

  '&:focus-visible': {
    boxShadow: `inset 0 0 0 2px ${theme.color.surface.black100}`,
  },

  '&:focus': {
    outline: '2px solid transparent',
    outlineOffset: theme.space(1),
  },

  'input:placeholder-shown + &': {
    display: 'none',
  },
}))
