import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import { Icon, TypographyText } from '@nordic-web/ui-components'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

type VoucherBadgeProps = {
  handleRemove: () => void
  voucherDuration: number
  voucherDiscount: { type: 'AMOUNT' | 'FRACTION'; value: number }
  period: string
  isRecurring: boolean
}

export const VoucherBadge = ({
  handleRemove,
  voucherDuration,
  voucherDiscount: { type: voucherType, value: discountValue },
  period = 'P1M',
  isRecurring = false,
}: VoucherBadgeProps) => {
  const t = useTranslations()
  return (
    <Wrapper>
      <TypographyText nwVariant="packageCardBody">
        {isRecurring
          ? t('subscription__voucher__info_recurring', { amount: discountValue })
          : t(
              `subscription__voucher__info_${voucherType.toLowerCase() as 'amount' | 'fraction'}${
                period.endsWith('W') ? '__week' : ''
              }`,
              {
                amount: discountValue,
                duration: voucherDuration,
              }
            )}
      </TypographyText>
      <Icon nwVariant="close-x-filled" nwClickable onClick={handleRemove} />
    </Wrapper>
  )
}
