import type { Conversions } from '@nordic-web/rest-codegen/generated/subscription'
import { useSubscription } from '@/features/checkout/hooks/use-subscription'
import { useIap } from '@/features/packages/hooks/use-iap'
import { useTierChangeEnabled } from '@/hooks/use-tier-change-enabled'

export const useConversionCheck = (conversion?: Conversions) => {
  const {
    subscriptionState: { data: subscriptionState },
  } = useSubscription()
  const { hasIap } = useIap()
  const isTierChangeEnabled = useTierChangeEnabled()

  const activeSubscription = subscriptionState?.active
  const isBoundToSubscription = activeSubscription && activeSubscription.isCurrentlyBoundToThisProduct

  const isConversionDisabled = hasIap || !isTierChangeEnabled || (isBoundToSubscription && conversion === 'DOWNGRADE')

  return { isConversionDisabled }
}
