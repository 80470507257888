import React, { useState } from 'react'
import { useTranslations } from 'next-intl'
import { useForm } from 'react-hook-form'
import {
  Button,
  ButtonGroup,
  FormInputPassword,
  Stack,
  TypographyText,
  useFormInputNumberMaxLength,
} from '@nordic-web/ui-components'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { AuthModalPaths, ChildLockFormViews } from '@/features/auth/types'

type PinCodeForm = {
  pinCode: string
}

type VerifyDisableFormProps = {
  errorMessage?: string
  loading: boolean
  pinCode: string
  onClose: () => void
  onSubmit: (params: { pinCode: string }) => void
  isChangingPin?: boolean
}

export const DisableWithPinCode = ({
  errorMessage,
  loading,
  onSubmit,
  onClose,
  pinCode,
  isChangingPin,
}: VerifyDisableFormProps) => {
  const t = useTranslations()
  const { dispatch: modalDispatch } = useAuthModal()
  const [isMismatch, setIsMismatch] = useState(false)
  const { register, handleSubmit, watch, setValue } = useForm<PinCodeForm>()
  const watchPinCode = watch('pinCode')

  useFormInputNumberMaxLength<PinCodeForm>({
    fieldName: 'pinCode',
    maxLength: 4,
    watchValue: watchPinCode,
    setValue,
  })

  return (
    <form
      data-test="remove-childlock"
      onSubmit={handleSubmit((data) => {
        if (pinCode && pinCode !== data.pinCode) {
          setIsMismatch(true)
        } else {
          setIsMismatch(false)
          onSubmit(data)
        }
      })}
    >
      <Stack nwGap={4}>
        <FormInputPassword nwLabel={t('settings__child_lock__input_label')} validators={register('pinCode')} required />

        {isMismatch && (
          <TypographyText nwVariant="smallText" nwColor="error">
            {t('child_lock__wrong_pincode')}
          </TypographyText>
        )}
        {!!errorMessage && (
          <TypographyText nwVariant="smallText" nwColor="error">
            {errorMessage}
          </TypographyText>
        )}
        <ButtonGroup nwAxis="y">
          <Button data-test="save-form" nwLoading={loading} type="submit">
            {isChangingPin ? t('general__continue') : t('child_lock__turn_off_button')}
          </Button>
          <Button onClick={onClose} nwVariant="secondaryTransparent">
            {t('general__cancel')}
          </Button>
          <Button
            data-test="edit-pin-code-password-link"
            onClick={() => {
              modalDispatch({
                type: 'show-auth-modal',
                path: AuthModalPaths.CHILDLOCK,
                pathParams: {
                  formView: isChangingPin
                    ? ChildLockFormViews.CHANGE_WITH_PASSWORD
                    : ChildLockFormViews.DISABLE_WITH_PASSWORD,
                },
              })
            }}
            nwVariant="ghost"
          >
            {t('child_lock__recover_pin')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  )
}
