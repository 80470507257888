import styled from 'styled-components'
import { Button } from '@nordic-web/ui-components'

export const VoucherButton = styled(Button)(({ theme }) => ({
  color: theme.color.surface.white60,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  display: 'inline',
  border: '1px solid',
  borderColor: theme.color.surface.white25,
  paddingLeft: 0,
}))
