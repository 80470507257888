import { useCallback, useEffect, useRef, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import type { AllFavoritesLightQuery } from '@nordic-web/gql'
import { AllFavoritesLightDocument } from '@nordic-web/gql'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import type { FavoriteId } from '@/features/favorite/common-types'
import * as utils from '@/features/favorite/utils'
import { useAddFavorite, useRemoveFavorite } from './use-favorites'

// This hook is optimized to only update when the favorite state of the given entity has been changed.
// This is important since the hook is used in the FavoriteButton component, which is used in many cards rendered by the pages.
export function useFavorite(props: FavoriteId) {
  const { isLoggedIn } = useAuthState()
  const internalStateRef = useRef({ loading: isLoggedIn, isFavorite: false })
  const [isLoading, setIsLoading] = useState(isLoggedIn)
  const [isFavorite, setIsFavorite] = useState(false)
  const apolloClient = useApolloClient()

  // Since the useFavorite hook is used by many small components (such as the favorite button in each card),
  // we wish it to only rerender for favorite state changes of our given program/asset for the hook instance.
  // Therefore, we cannot simply use a useQuery here as it would rerender for any change in the favorites list.
  // Instead we have a more manual approach where we subscribe to the query and update our internal favorite
  // state when the given program/asset has changed favorite state.
  useEffect(() => {
    if (isLoggedIn) {
      const subscription = apolloClient
        .watchQuery<AllFavoritesLightQuery>({
          query: AllFavoritesLightDocument,
        })
        .subscribe({
          next: ({ data }) => {
            if (internalStateRef.current.loading) {
              internalStateRef.current.loading = false
              setIsLoading(false)
            }
            const favorites = data?.favorites?.items
            if (favorites) {
              const isFavorite = utils.isFavorite(favorites, props.id)
              if (isFavorite !== internalStateRef.current.isFavorite) {
                internalStateRef.current.isFavorite = isFavorite
                setIsFavorite(isFavorite)
              }
            }
          },
        })
      return () => {
        subscription.unsubscribe()
      }
    }
  }, [isLoggedIn, apolloClient, setIsFavorite, props.id, setIsLoading])

  const addFavorite = useAddFavorite()
  const removeFavorite = useRemoveFavorite()

  const onAddFavorite = useCallback(() => {
    addFavorite(props)
  }, [addFavorite, props])

  const onRemoveFavorite = useCallback(() => {
    removeFavorite(props)
  }, [removeFavorite, props])

  return {
    isFavorite,
    addFavorite: onAddFavorite,
    removeFavorite: onRemoveFavorite,
    isLoading,
  }
}
