import packageJson from '@@/package.json'
import { HttpLink, from, split } from '@apollo/client'
import { ApolloClient } from '@apollo/client/core'
import type { NormalizedCacheObject } from '@apollo/client/core'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import Bugsnag from '@bugsnag/js'
import { sha256 } from 'crypto-hash'
import { v4 as uuidv4 } from 'uuid'
import { createInMemoryCache } from '@nordic-web/gql/src/apollo-in-memory-cache'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { parseJwt } from '@nordic-web/utils/authentication/token'
import { isClientSide } from '@nordic-web/utils/misc/detect-side'
import { isValidTierValue } from '@/components/tier-override-select'
import { brandConfig } from '@/config/brand'
import { nextConfig } from '@/helpers/env'

type AccessToken = string | undefined
type GetAccessToken = () => Promise<AccessToken> | AccessToken

let apolloClient: ApolloClient<NormalizedCacheObject> | null

const GRAPHQL_URL = nextConfig.string('GRAPHQL_URL')
// This is nice when testing to see what query is actually requested in dev tools
const shouldDisablePersistedQueries = nextConfig.bool('DISABLE_PERSISTED_QUERIES')

const tierOverrideLink = setContext((_, { headers }) => {
  if (isClientSide) {
    let tierOverride = ''
    try {
      tierOverride = localStorage.getItem('tierOverride') || ''
    } catch (error) {
      console.log(error)
    }

    if (isValidTierValue(tierOverride)) {
      return {
        headers: {
          'tier-override-id': tierOverride,
          ...headers,
        },
      }
    }
  }

  return { headers }
})

const correlationIdLink = setContext((_, { headers }) => {
  return {
    headers: {
      'x-correlation-id': uuidv4(),
      ...headers,
    },
  }
})

const authLink = (getAccessToken: GetAccessToken) => {
  return setContext(async (_, { headers }) => {
    const accessToken = await getAccessToken()

    if (accessToken) {
      const entitlements = parseJwt(accessToken)?.entitlements
      return {
        headers: {
          ...headers,
          authorization: formatAuthorizationHeader(accessToken),
          // This header is sent for the CDN to be able to cache public content that depends on tier level
          ...(entitlements ? { 'user-entitlements': entitlements?.join(',') } : {}),
        },
      }
    }

    return {
      headers,
    }
  })
}

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    if ('statusCode' in networkError && networkError.statusCode !== undefined) {
      Bugsnag.notify('A network error occured when calling the graphql API', (event) => {
        event.addMetadata('details', {
          errorCode: networkError.statusCode,
          rawError: networkError,
        })
      })
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      Bugsnag.notify('A graphql error was returned from the graphql API', (event) => {
        event.addMetadata('details', {
          rawError: error,
        })
      })
    })
  }
})

const featureFlagHeaders = {
  'feature-toggle-include-sportevents-in-endscreen-recommendations': 'true',
}

const commonHttpOptions = () => ({
  uri: GRAPHQL_URL + '/graphql',
  credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  headers: {
    'client-name': brandConfig.clientName,
    'client-version': packageJson.version,
    ...featureFlagHeaders,
  },
  fetch,
})

const batchLink = () =>
  new BatchHttpLink({
    ...commonHttpOptions(),
    batchMax: 20, // a max number of items to batch, defaults at 10
  })

const persistedQueryhttpLink = () => {
  const httpLink = new HttpLink(commonHttpOptions())

  if (shouldDisablePersistedQueries) {
    return from([correlationIdLink, httpLink])
  }

  return from([correlationIdLink, createPersistedQueryLink({ useGETForHashedQueries: true, sha256 }), httpLink])
}

type SafeApolloClient = ApolloClient<NormalizedCacheObject> & {
  toJSON?: () => null
}

function create(getAccessToken: GetAccessToken, stateFromServer?: NormalizedCacheObject) {
  const client: SafeApolloClient = new ApolloClient({
    connectToDevTools: isClientSide,
    ssrMode: !isClientSide, // Disables forceFetch on the server (so queries are only run once)
    link: from([
      errorLink,
      tierOverrideLink,
      authLink(getAccessToken),
      split((operation) => operation.getContext().batch, batchLink(), persistedQueryhttpLink()),
    ]),
    cache: createInMemoryCache().restore(stateFromServer || {}),
  })

  // See this issue for more info: https://github.com/vercel/next.js/issues/9336#issuecomment-1092830219
  client.toJSON = () => null

  return client
}

export function initApollo(getAccessToken: GetAccessToken = () => undefined, stateFromServer?: NormalizedCacheObject) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isClientSide) {
    return create(getAccessToken, stateFromServer)
  }
  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(getAccessToken, stateFromServer)
  }

  return apolloClient
}
