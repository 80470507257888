import { useState } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Avatar, Button, TypographyText } from '@nordic-web/ui-components'
import { useIsClientSide } from '@nordic-web/utils/hooks/use-is-client-side'
import { handleEnterKeyDown } from '@nordic-web/utils/misc/keyboard'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { AuthModalPaths } from '@/features/auth/types'
import { useUserQuery } from '@/features/profiles/hooks/use-user-query'
import { paths } from '@/helpers/paths'
import { useNoHamburgerMenu } from '@/layouts/header/hooks'
import { HeaderDropdownMenu } from './dropdown'
import { IconItem } from './style'

const ProfileHeaderItem = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { data, isLoading } = useUserQuery()

  const router = useRouter()
  const { name = '', avatarUrl = '' } = data?.user?.activeProfile || {}
  const profiles = data?.user?.profiles || []
  const activeProfileId = data?.user?.activeProfile?.id

  const shouldShowDesktopMenu = useNoHamburgerMenu()

  if (isLoading) return null

  const handleClick = () => {
    if (!shouldShowDesktopMenu) {
      router.push(paths.selectProfile.urlString({ return: router.asPath }))
    }
  }

  const handleToggleDropdown = (value: boolean) => {
    if (!shouldShowDesktopMenu) return
    setIsDropdownOpen(value)
  }

  return (
    <Li onMouseEnter={() => handleToggleDropdown(true)} onMouseLeave={() => handleToggleDropdown(false)}>
      <Avatar
        nwName={name}
        nwImageUrl={avatarUrl}
        nwSize="small"
        role="menuitem"
        tabIndex={0}
        nwAxis="x"
        onKeyDown={handleEnterKeyDown(() => {
          handleToggleDropdown(!isDropdownOpen)
        })}
        onClick={handleClick}
        nwSelectable={false}
      >
        {shouldShowDesktopMenu && <TypographyText nwVariant="body3">{name}</TypographyText>}
      </Avatar>
      {isDropdownOpen && (
        <HeaderDropdownMenu
          profiles={profiles}
          activeProfileId={activeProfileId}
          onClose={() => handleToggleDropdown(false)}
        />
      )}
    </Li>
  )
}

const Li = styled.li({
  position: 'relative',
})

export const User = () => {
  const t = useTranslations()
  const { isLoggedIn, hasValidAccessToken } = useAuthState()
  const { dispatch } = useAuthModal()

  // The user is read client-side only, so we need to have a double render-pass in order to avoid
  // warnings about mismatching SSR.
  const isClientSide = useIsClientSide()

  if (!isClientSide) {
    // SSR cannot know if we are logged in or not. Render an empty placeholder with matching width.
    return (
      <IconItem data-test="">
        <svg style={{ visibility: 'hidden' }} />
      </IconItem>
    )
  }

  if (isLoggedIn && !hasValidAccessToken) {
    return null
  }

  // we use token here instead of user, because user may not have been fetched yet.
  // and we want to avoid flashing the anonymous icon before the user has been loaded.
  // Instead, we'll render the red circle without the initials.
  if (hasValidAccessToken) {
    return <ProfileHeaderItem />
  }

  return (
    <Button
      nwVariant="outline"
      nwSize="small"
      onClick={() => {
        dispatch({ type: 'show-auth-modal', path: AuthModalPaths.LOGIN })
      }}
    >
      {t('general__log_in')}
    </Button>
  )
}
