import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Button } from '@nordic-web/ui-components'
import { tablet } from '@nordic-web/ui-styles'
import { MaxWidthContainerSm } from '@/components/max-width-container/max-width-container-sm'
import { getSafeReturnPathFromParam } from '@/helpers/get-safe-return-path-from-param'
import { paths } from '@/helpers/paths'

const ButtonWrapperArrow = styled.div<{ onlyShowOnMobile: boolean }>(({ onlyShowOnMobile }) => ({
  marginRight: 'auto',
  [tablet]: {
    display: onlyShowOnMobile ? 'none' : 'block',
  },
}))

export const GoBackButtonArrow = ({
  href,
  onlyShowOnMobile = false,
}: {
  href?: string
  onlyShowOnMobile?: boolean
}) => {
  const router = useRouter()
  const t = useTranslations()

  return (
    <ButtonWrapperArrow onlyShowOnMobile={onlyShowOnMobile}>
      <Link href={href || getSafeReturnPathFromParam(router.query) || paths.settings.urlString()}>
        <Button nwVariant="ghost" nwIconFirst="arrow-left">
          {t('general__back')}
        </Button>
      </Link>
    </ButtonWrapperArrow>
  )
}

export const GoBackButton = ({ href, variant = 'cancel' }: { href?: string; variant?: 'cancel' | 'back' }) => {
  const router = useRouter()
  const t = useTranslations()

  return (
    <MaxWidthContainerSm>
      <Link href={href || getSafeReturnPathFromParam(router.query) || paths.settings.urlString()}>
        <Button nwVariant="ghost" nwLayout="full-width">
          {t(`general__${variant}`)}
        </Button>
      </Link>
    </MaxWidthContainerSm>
  )
}

type GoBackArrowProps = {
  onlyShowOnMobile?: boolean
  onClickHandler?: () => void
}

export const GoBackArrow = ({ onlyShowOnMobile = false, onClickHandler }: GoBackArrowProps) => {
  const router = useRouter()
  const t = useTranslations()
  const goBack = () => router.back()
  return (
    <ButtonWrapperArrow onlyShowOnMobile={onlyShowOnMobile}>
      <Button nwVariant="ghost" onClick={onClickHandler ?? goBack} nwIconFirst="arrow-left">
        {t('general__back')}
      </Button>
    </ButtonWrapperArrow>
  )
}
