import Bugsnag from '@bugsnag/js'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  CredentialsPayload,
  UpdateEmailPayload,
  UpdatePasswordPayload,
  UpdateTv4ChildLockPayload,
  UpdateTv4ChildLockWithPasswordPayload,
  UpdateUserPayload,
  User,
} from '@nordic-web/rest-codegen/generated/account'
import * as AccountService from '@nordic-web/rest-codegen/generated/account'
import { ApiError } from '@nordic-web/rest-codegen/generated/account'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { parseJwt } from '@nordic-web/utils/authentication/token'
import { brandConfig } from '@/config/brand'
import { useMenuState } from '@/context/menu-state-context'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { getStaleAndCacheTime } from '@/features/auth/helpers/get-stale-and-cache-time'
import { useTokenRefreshOn401 } from '@/features/auth/hooks/use-token-refresh-on-401'
import { AuthTracking } from '@/features/auth/tracking-events'
import { nextConfig } from '@/helpers/env'
import { getPageName } from '@/helpers/get-page-name'

const aDayInMilliseconds = 1000 * 60 * 60 * 24

const ACCOUNT_HOST_API = nextConfig.string('ACCOUNT_HOST_API')

AccountService.OpenAPI.BASE = ACCOUNT_HOST_API

const queryKey = ['user']

export const useAccountApi = () => {
  const { dispatch: authModalDispatch } = useAuthModal()
  const { setIsMobileMenuOpen } = useMenuState()
  const { accessToken, hasValidAccessToken, logout } = useAuthState()

  const authorization = formatAuthorizationHeader(accessToken)
  const queryClient = useQueryClient()

  const onError = (error: ApiError) => console.error(`Use account api error: ${error}`)
  const onErrorWithLogout = (error: ApiError) => {
    if ([401, 404].includes(error.status)) {
      logout()
      const accessTokenData = parseJwt(accessToken)
      Bugsnag.notify('User got logged out because of an error in the account API', (event) => {
        event.addMetadata('details', {
          exp: accessTokenData?.exp,
          iat: accessTokenData?.iat,
        })
      })
    }
  }
  const onUserQuerySuccess = (user: User) => {
    authModalDispatch({ type: 'close-auth-modal' })
    setIsMobileMenuOpen(false)
    Bugsnag.setUser(user.userId)
  }
  const callTokenRefreshOn401 = useTokenRefreshOn401()

  const userQuery = useQuery({
    queryKey,
    retry: 1,
    queryFn: async () => {
      try {
        const data = await callTokenRefreshOn401((token) =>
          AccountService.accountWebUserControllerMe({
            authorization: formatAuthorizationHeader(token),
            client: brandConfig.clientName,
          })
        )
        onUserQuerySuccess(data)

        return data
      } catch (error) {
        if (error instanceof ApiError) {
          onErrorWithLogout(error)
        }
        throw error
      }
    },
    enabled: hasValidAccessToken,
    ...getStaleAndCacheTime(aDayInMilliseconds),
  })

  const userMutation = useMutation({
    mutationFn: (payload: UpdateUserPayload) =>
      AccountService.accountWebUserControllerUpdateUser({
        authorization,
        payload,
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data)
    },
    onError: onErrorWithLogout,
  })

  // Since vimond is slow with updating these fields, we need to optimistically set the response data for the marketing settings
  const optimisticUserMutation = useMutation({
    mutationFn: (payload: UpdateUserPayload) =>
      AccountService.accountWebUserControllerUpdateUser({
        authorization,
        payload,
      }),
    onMutate: async (marketingSettings) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey })

      // Save the previous state, in case of error
      const previousUser = queryClient.getQueryData(queryKey)

      // Optimistically update to the new value
      queryClient.setQueryData<UpdateUserPayload>(queryKey, (userPayload) => ({
        ...userPayload,
        ...marketingSettings,
      }))

      return { previousUser }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_error: ApiError, _variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousUser)
    },
  })

  const emailMutation = useMutation({
    mutationFn: (payload: UpdateEmailPayload) =>
      AccountService.accountWebUserControllerUpdateEmail({
        authorization,
        payload,
      }),
    onError,
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data)
    },
  })

  const deleteUserMutation = useMutation({
    mutationFn: (payload: CredentialsPayload) =>
      AccountService.accountWebUserControllerDeleteUser({
        payload,
      }),
    onSuccess: () => {
      logout()
    },
    onError,
  })

  const passwordMutation = useMutation({
    mutationFn: (payload: UpdatePasswordPayload) =>
      AccountService.accountWebPasswordControllerUpdatePassword({
        authorization,
        payload,
      }),
    onError,
  })

  const editChildLockMutation = useMutation({
    mutationFn: (payload: UpdateTv4ChildLockPayload) =>
      AccountService.accountWebUserControllerUpdateTv4ChildLock({
        authorization,
        payload,
      }),
    onError,
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data)
    },
  })

  const disableChildLockWithPasswordMutation = useMutation({
    mutationFn: (payload: UpdateTv4ChildLockWithPasswordPayload) =>
      AccountService.accountWebUserControllerUpdateTv4ChildLockWithPassword({
        authorization,
        payload,
      }),
    onError,
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data)
    },
  })

  const logoutOptions: UseMutationOptions = {
    onSuccess: () => {
      AuthTracking.onLogoutSuccess(userQuery.data?.userId ?? '', getPageName() ?? '')
    },
    onSettled: () => {
      logout()
    },
  }

  const logoutMutation = useMutation({
    mutationFn: () =>
      AccountService.accountWebSessionControllerLogout({
        authorization,
      }),
    ...logoutOptions,
  })
  const logoutAllMutation = useMutation({
    mutationFn: () =>
      AccountService.accountWebSessionControllerLogoutAll({
        authorization,
      }),
    ...logoutOptions,
  })

  return {
    user: userQuery,
    userMutation,
    optimisticUserMutation,
    emailMutation,
    logoutMutation,
    logoutAllMutation,
    passwordMutation,
    deleteUserMutation,
    editChildLockMutation,
    disableChildLockWithPasswordMutation,
  }
}
