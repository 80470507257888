import { useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import * as SubscriptionService from '@nordic-web/rest-codegen/generated/subscription'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { getStaleAndCacheTime } from '@/features/auth/helpers/get-stale-and-cache-time'
import { useHasLoginStateChanged } from '@/features/auth/hooks/use-has-login-state-changed'
import { withSubscriptionApiErrorCallback } from '@/features/checkout/hooks/use-subscription'
import { nextConfig } from '@/helpers/env'
import { stringFromQueryParam } from '@/utils/query-string'

SubscriptionService.OpenAPI.BASE = nextConfig.string('SUBSCRIPTION_API') + '/v1'

const oneDayInMilliseconds = 24 * 60 * 60 * 1000
const staleAndCacheTime = getStaleAndCacheTime(oneDayInMilliseconds)

export const smallPackageKey = ['packages', 'small']
export const packageKey = ['packages']

export const usePackages = () => {
  const { accessToken, hasValidAccessToken, isLoggedIn } = useAuthState()
  const router = useRouter()
  const packageId = stringFromQueryParam(router.query?.packageId) ?? ''
  const token = formatAuthorizationHeader(accessToken)
  const queryClient = useQueryClient()

  const hasLoginStateChanged = useHasLoginStateChanged()

  const allPackages = useQuery({
    queryKey: packageKey,
    queryFn: withSubscriptionApiErrorCallback(() =>
      SubscriptionService.getAllPackages({
        authorization: token,
      })
    ),
    enabled: !isLoggedIn || !!hasValidAccessToken,
    ...staleAndCacheTime,
  })

  const allSmallPackageCards = useQuery({
    queryKey: smallPackageKey,
    queryFn: withSubscriptionApiErrorCallback(() =>
      SubscriptionService.getAllPackages({
        authorization: token,
        size: 'small',
      })
    ),
    enabled: !isLoggedIn || !!hasValidAccessToken,
    ...staleAndCacheTime,
  })

  const mediumPackageCard = useQuery({
    queryKey: ['package', 'medium', packageId],
    queryFn: withSubscriptionApiErrorCallback(() =>
      SubscriptionService.getSinglePackage({
        vimondId: packageId,
        size: 'medium',
      })
    ),
    enabled: !!packageId,
    ...staleAndCacheTime,
  })

  const largePackageCard = useQuery({
    queryKey: ['package', packageId],
    queryFn: withSubscriptionApiErrorCallback(() =>
      SubscriptionService.getSinglePackage({
        vimondId: packageId,
      })
    ),
    enabled: !!packageId,
    ...staleAndCacheTime,
  })

  useEffect(() => {
    if (hasLoginStateChanged) {
      queryClient.fetchQuery({ queryKey: packageKey })
      queryClient.fetchQuery({ queryKey: smallPackageKey })
    }
  }, [hasLoginStateChanged, queryClient])

  return {
    allPackages,
    allSmallPackageCards,
    mediumPackageCard,
    largePackageCard,
  }
}
