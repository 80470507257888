import { useCallback, useEffect, useState } from 'react'
import { useReno } from '@/features/reno/hooks/use-reno'
import type { RenoNotification } from '@/features/reno/types'
import { publishEvent } from '@/hooks/use-global-events'

export const RENO_EVENT = 'reno-notification'

export const RenoNotificationListener = () => {
  const [lastSeen, setLastSeen] = useState(Date.now())
  const { socketNotification, httpNotifications } = useReno(lastSeen)
  const [publishedNotifications, setPublishedNotifications] = useState<string[]>([])

  const handleNotification = useCallback(
    (notification: RenoNotification) => {
      const isPublished = publishedNotifications.includes(notification.id)
      if (!isPublished) {
        setLastSeen(notification.timestamp)
        publishEvent(RENO_EVENT, notification)
        setPublishedNotifications([...publishedNotifications, notification.id])
      }
    },
    [publishedNotifications]
  )

  useEffect(() => {
    if (socketNotification) {
      handleNotification(socketNotification)
    }
  }, [socketNotification, handleNotification])

  useEffect(() => {
    if (httpNotifications) {
      httpNotifications.forEach((httpNotification) => handleNotification(httpNotification))
    }
  }, [httpNotifications, handleNotification])

  return null
}
