import type { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Stack } from '../../components/layout/stack-redux/stack'
import { TypographyText } from '../typography/typography-text/typography-text'

export const ToggleContainer = styled(Stack)<{ disabled: boolean }>(({ disabled, theme }) => ({
  background: theme.color.surface.cardDefault,
  backdropFilter: 'blur(15px)',
  borderRadius: 100,
  ...(disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
  width: 'fit-content',
  padding: theme.space(1),
}))

export const ToggleButton = styled.div<{ isActive: boolean }>(({ isActive, theme }) => ({
  flex: 1,
  textAlign: 'center',
  borderRadius: 100,
  padding: theme.space(2),
  paddingInline: theme.space(6),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  ...(isActive
    ? {
        background: theme.color.surface.white20,
        color: theme.color.text.primary,
      }
    : {
        color: theme.color.text.tertiary,
        '&:hover': {
          color: theme.color.text.secondary,
          background: theme.color.surface.white5,
        },
      }),
}))

export const HeadToggleText = ({ children }: PropsWithChildren) => (
  <TypographyText nwVariant="CTA">{children}</TypographyText>
)
