const allPaths = {
  abroad: {
    mtv: '',
    tv4: '/utomlands',
  },
  activateOperator: {
    mtv: '/aktivoi-operaattori',
    tv4: '/aktivera-operator',
  },
  addPaymentMethods: {
    mtv: '/omatili/maksutavat/uusi',
    tv4: '/installningar/betalmetod/ny',
  },
  adSettings: {
    mtv: '/saada-mainoksia',
    tv4: '/justera-annonser',
  },
  allPrograms: {
    mtv: '/kaikki-ohjelmat',
    tv4: '/alla-program',
  },
  campaign: {
    mtv: '/kampanja',
    tv4: '/kampanj',
    params: '/:id',
  },
  categories: {
    mtv: '/ohjelmat',
    tv4: '/kategorier',
    params: '/:id*',
  },
  changePackage: {
    mtv: '/omatili/tuotteet',
    tv4: '/installningar/paket',
  },
  changePassword: {
    mtv: '/uusi-salasana',
    tv4: '/byt-losenord',
    params: '/:token',
  },
  channels: {
    mtv: '/kanavat',
    tv4: '/kanaler',
  },
  channelsTableau: {
    // Params needs to be synced with channel-epg-panel/index.tsx if changed
    mtv: '/kanavat/aikataulu',
    tv4: '/kanaler/tabla',
  },
  checkoutAlterCurrent: {
    mtv: '/maksuvaihe/nykyinen',
    tv4: '/kassa/nuvarande',
  },
  checkout: {
    mtv: '/maksuvaihe',
    tv4: '/kassa',
    params: '/:packageId',
  },
  checkoutOutcomeCancel: {
    mtv: '/maksuvaihe/lopputulos/peruutus',
    tv4: '/kassa/resultat/avbryt',
    params: '/:outcome',
  },
  checkoutOutcomeDowngrade: {
    mtv: '/maksuvaihe/lopputulos/vaihdatilausta',
    tv4: '/kassa/resultat/justera',
    params: '/:outcome',
  },
  checkoutOutcomeReactivate: {
    mtv: '/maksuvaihe/lopputulos/uudelleenaktivoi',
    tv4: '/kassa/resultat/ateraktivera',
    params: '/:outcome',
  },
  checkoutOutcomeUpgrade: {
    mtv: '/maksuvaihe/lopputulos/tilauksenvaihto',
    tv4: '/kassa/resultat/uppgradera',
    params: '/:resultCode',
  },
  checkoutOutcomeAlterCurrent: {
    mtv: '/maksuvaihe/lopputulo/nykyinen',
    tv4: '/kassa/resultat/nuvarande',
  },
  checkoutPayment: {
    mtv: '/maksuvaihe/maksaa',
    tv4: '/kassa/betala',
    params: '/:packageId',
  },
  clip: {
    mtv: '/lyhyet',
    tv4: '/klipp',
    params: '/:section*',
  },
  contentDetailsPage: {
    mtv: '/ohjelma',
    tv4: '/program',
    params: '/:id*',
  },
  faq: {
    mtv: '/asiakaspalvelu',
    tv4: '/faq',
  },
  forgotPassword: {
    mtv: '/unohtuiko-salasana',
    tv4: '/glomt-losenord',
  },
  list: {
    mtv: '/lista',
    tv4: '/lista',
  },
  myDataRegister: {
    mtv: '/rekisteriote',
    tv4: '/registerutdrag',
  },
  myList: {
    mtv: '/oma-lista',
    tv4: '/min-lista',
  },
  news: {
    mtv: '/uutisohjelmat',
    tv4: '/nyheter',
  },
  packages: {
    mtv: '/tuotteet',
    tv4: '/paket',
    params: '/:tierId',
  },
  paymentMethods: {
    mtv: '/omatili/maksutavat',
    tv4: '/installningar/betalmetod',
  },
  program: {
    mtv: '/program',
    tv4: '/program',
  },
  receipts: {
    mtv: '/omatili/kuitit',
    tv4: '/installningar/kvitton',
  },
  removeAccount: {
    mtv: '/poista-minut',
    tv4: '/ta-bort-mig',
  },
  search: {
    mtv: '/hae',
    tv4: '/sok',
    params: '/:params*',
  },
  settings: {
    mtv: '/omatili',
    tv4: '/installningar',
  },
  signup: {
    mtv: '/?luo-kayttajatili',
    tv4: '/?skapa-konto',
  },
  sport: {
    mtv: '/urheilu',
    tv4: '/sport',
  },
  sportEpg: {
    mtv: '/urheilu/aikataulu',
    tv4: '/sport/tabla',
  },
  start: {
    mtv: '/',
    tv4: '/',
  },
  video: {
    mtv: '/video',
    tv4: '/video',
  },
  videoEmbed: {
    mtv: '/iframe/video',
    tv4: '/iframe/video',
  },
  selectProfile: {
    mtv: '/profiilit/valinta',
    tv4: '/profiler/valj',
  },
  editProfiles: {
    mtv: '/profiilit/muokkaus',
    tv4: '/profiler/redigera',
  },
  editProfile: {
    mtv: '/profiilit/muokkaus',
    tv4: '/profiler/redigera',
    params: '/:id',
  },
  createProfile: {
    mtv: '/profiilit/uusi',
    tv4: '/profiler/ny',
  },
  deleteProfile: {
    mtv: '/profiilit/poista',
    tv4: '/profiler/ta-bort',
    params: '/:id',
  },
}

const hasNoEmptyStrings = (obj) => Object.values(obj).every((value) => value !== '')
const isNotQueryParam = (obj) => Object.values(obj).every((value) => value.charAt(1) !== '?')
const isNotSame = (obj) => obj.tv4 !== obj.mtv

const autoRewrites = Object.values(allPaths)
  .filter(isNotSame)
  .filter(hasNoEmptyStrings)
  .filter(isNotQueryParam)
  .map(({ mtv, tv4, params }) => ({
    source: mtv + (params || ''),
    destination: tv4 + (params || ''),
  }))

const extraRewrites = [
  {
    source: allPaths.packages.mtv,
    destination: allPaths.packages.tv4,
  },
  {
    source: allPaths.start.tv4,
    destination: allPaths.abroad.tv4,
  },
]

/**
 * Rewrites used in next config
 */
const rewrites = [...autoRewrites, ...extraRewrites]

/**
 * Redirects used in next config
 */
const redirects = rewrites.map(({ source, destination }) => ({
  destination: source,
  source: destination,
  permanent: true,
}))

module.exports = {
  allPaths,
  rewrites,
  redirects,
}
