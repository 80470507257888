import { useCallback } from 'react'
import type { ApolloCache } from '@apollo/client'
import { useApolloClient } from '@apollo/client'
import { useTranslations } from 'next-intl'
import type {
  AddFavoriteMutation,
  AddFavoriteMutationVariables,
  AllFavoritesLightQuery,
  FavoriteMutationInput,
  RemoveFavoriteMutation,
  RemoveFavoriteMutationVariables,
} from '@nordic-web/gql'
import { AddFavoriteDocument, AllFavoritesLightDocument, FavoriteType, RemoveFavoriteDocument } from '@nordic-web/gql'
import { useNotificationSnackbar } from '@nordic-web/ui-components'
import { nonNullable } from '@nordic-web/utils/typescript'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { AuthModalPaths } from '@/features/auth/types'
import type { FavoriteItem } from '@/features/favorite/common-types'
import { removeFavorite } from '@/features/favorite/utils'

const getFavoriteCacheEntry = (type: FavoriteType, id: string): FavoriteItem => {
  switch (type) {
    case FavoriteType.Series:
      return {
        __typename: 'FavoriteSeriesItem',
        series: {
          __typename: 'Series',
          id,
        },
      }
    case FavoriteType.Movie:
      return {
        __typename: 'FavoriteMovieItem',
        movie: {
          __typename: 'Movie',
          id,
        },
      }
    default:
      throw new Error(`Unknown favorite type: ${type}`)
  }
}

const removeFavoriteFromCache = <T extends ApolloCache<unknown>>(cache: T, id: string) => {
  const data = cache.readQuery<AllFavoritesLightQuery>({
    query: AllFavoritesLightDocument,
  })
  const favoritesInCache = data?.favorites?.items?.filter(nonNullable) ?? []
  const { favorite, favorites } = removeFavorite(favoritesInCache, id)
  if (favorite) {
    cache.writeQuery<AllFavoritesLightQuery>({
      query: AllFavoritesLightDocument,
      data: {
        __typename: 'Query',
        favorites: { __typename: 'Favorites', items: favorites },
      },
    })

    cache.evict({ id: cache.identify(favorite) })
  }
}

export const useAddFavorite = () => {
  const apolloClient = useApolloClient()
  const { isLoggedIn } = useAuthState()
  const { dispatch } = useAuthModal()
  const { showSnackbarMessage } = useNotificationSnackbar()
  const t = useTranslations()

  return useCallback(
    ({ id, type }: FavoriteMutationInput) => {
      const add = async () => {
        if (!isLoggedIn) {
          dispatch({ type: 'show-auth-modal', path: AuthModalPaths.LOGIN })
          return
        }

        await apolloClient.mutate<AddFavoriteMutation, AddFavoriteMutationVariables>({
          mutation: AddFavoriteDocument,
          variables: { favorite: { id: id, type: type } },
          optimisticResponse: {
            __typename: 'Mutation',
            addFavorite: {
              __typename: 'FavoriteMutationResponse',
            },
          },
          update: (cache) => {
            const data = cache.readQuery<AllFavoritesLightQuery>({
              query: AllFavoritesLightDocument,
            })

            if (!data?.favorites) {
              return
            }

            const newEntry = getFavoriteCacheEntry(type, id)

            cache.writeQuery<AllFavoritesLightQuery>({
              query: AllFavoritesLightDocument,
              data: {
                __typename: 'Query',
                favorites: { __typename: 'Favorites', items: [newEntry, ...data.favorites.items] },
              },
            })
            showSnackbarMessage({ text: t('snackbar__added_to_favorites') })
          },
        })
      }
      add()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apolloClient, isLoggedIn, dispatch, t]
  )
}

export const useRemoveFavorite = () => {
  const { dispatch } = useAuthModal()
  const apolloClient = useApolloClient()
  const { isLoggedIn } = useAuthState()
  const { showSnackbarMessage } = useNotificationSnackbar()
  const t = useTranslations()

  return useCallback(
    ({ id, type }: FavoriteMutationInput) => {
      const remove = async () => {
        if (!isLoggedIn) {
          dispatch({ type: 'show-auth-modal', path: AuthModalPaths.LOGIN })
          return
        }

        await apolloClient.mutate<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>({
          optimisticResponse: {
            __typename: 'Mutation',
            removeFavorite: {
              __typename: 'FavoriteMutationResponse',
            },
          },
          mutation: RemoveFavoriteDocument,
          variables: { favorite: { id, type } },
          update: (cache) => {
            removeFavoriteFromCache(cache, id)
            showSnackbarMessage({ text: t('snackbar__removed_from_favorites') })
          },
        })
      }
      remove()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apolloClient, dispatch, isLoggedIn, t]
  )
}
