import React from 'react'
import { Dialog } from '@nordic-web/ui-components'
import { AuthIframe, PATH_FORGOT_PASSWORD, PATH_LOGIN, PATH_SIGNUP } from '@/features/auth/components/auth-iframe'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { AuthModalPaths } from '@/features/auth/types'
import { ChildLockForms } from '@/features/child-lock/manage/child-lock-forms'
import { LogoutOthersConfirm } from './logout-others-confirm'

export const AuthModal = () => {
  const {
    state: { isOpen, path, pathParams },
    dispatch,
  } = useAuthModal()

  const handleClose = () => {
    dispatch({ type: 'close-auth-modal' })
  }

  const onBack = () => {
    if (!pathParams?.returnPath) return

    dispatch({
      type: 'show-auth-modal',
      path: pathParams.returnPath,
      pathParams: {
        formView: pathParams?.formView,
      },
    })
  }

  return (
    <Dialog nwInitOpen={isOpen} nwVariant="drawer" onClose={handleClose}>
      {isOpen && (
        <>
          <Dialog.Top />

          {path === AuthModalPaths.LOGIN && <AuthIframe onBack={onBack} path={PATH_LOGIN} />}
          {path === AuthModalPaths.FORGOT && (
            <AuthIframe onBack={onBack} path={PATH_FORGOT_PASSWORD} showBack={!!pathParams?.returnPath} />
          )}
          {path === AuthModalPaths.SIGNUP && <AuthIframe onBack={onBack} path={PATH_SIGNUP} />}
          {path === AuthModalPaths.LOGOUT_OTHERS && (
            <Dialog.DrawerBody>
              <LogoutOthersConfirm onCancelLogoutOther={handleClose} />
            </Dialog.DrawerBody>
          )}
          {path === AuthModalPaths.CHILDLOCK && (
            <Dialog.DrawerBody>
              <ChildLockForms formView={pathParams?.formView} onCancelChildLock={handleClose} />
            </Dialog.DrawerBody>
          )}
        </>
      )}
    </Dialog>
  )
}
