import { css } from 'styled-components'
import type { Theme } from '@nordic-web/ui-theme'

export const typography = (theme: Theme) => css`
  a {
    text-decoration: none;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    color: ${theme.color.text.primary};
    font-family: ${theme.fonts.body};
    font-weight: 400;
    line-height: 1.35;
  }
`
