import { useMemo } from 'react'
import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import type { Package, PackageProductData, VoucherResponse } from '@nordic-web/rest-codegen/generated/subscription'
import { Stack, TypographyText } from '@nordic-web/ui-components'
import { useCheckoutState } from '@/features/checkout/context/checkout-state-context'
import { OrderPeriods } from '@/features/checkout/types'
import { voucherIsApplied } from '@/features/checkout/upgrade'
import { formatPrice } from '@/utils/price'

export type PriceOverrideProps = Pick<PackageProductData, 'initPrice' | 'numberOfInitPeriods' | 'initPeriod'> & {
  numberOfBindingPeriods?: number
}

type PackagePriceProps = {
  packageData: Package
  voucherData?: VoucherResponse
  large?: boolean
  showBindingPrice?: boolean
  showVoucherPrice?: boolean
  showFromPrice?: boolean
  priceOverride?: PriceOverrideProps
}

const TextOptionalStrikeThrough = styled(TypographyText)<{ strikeThrough?: boolean }>(({ strikeThrough, theme }) => ({
  ...(strikeThrough ? { textDecoration: `${theme.color.surface.white50} line-through` } : {}),
}))

export const PackagePrice = ({
  packageData,
  voucherData,
  large = false,
  showBindingPrice = false,
  showVoucherPrice = false,
  showFromPrice = false,
  priceOverride,
}: PackagePriceProps) => {
  const t = useTranslations()

  const isVoucherAppliedAndVisible = voucherIsApplied(voucherData, packageData.vimondId) && showVoucherPrice
  const isTrialApplied = packageData.hasTrial
  const { isCheckingOutWithBinding } = useCheckoutState()
  const isBindingPriceVisible = isCheckingOutWithBinding && !!packageData.defaultBindingProduct && showBindingPrice

  const initPrice = useMemo(() => {
    if (priceOverride) {
      return formatPrice(priceOverride.initPrice)
    }
    if (isVoucherAppliedAndVisible) {
      return formatPrice(voucherData.discountedPrice)
    }
    if (isBindingPriceVisible && packageData.defaultBindingProduct) {
      return formatPrice(packageData.defaultBindingProduct.initPrice)
    }
    if (isTrialApplied) {
      return formatPrice(packageData.defaultProduct.initPrice)
    }

    return undefined
  }, [isVoucherAppliedAndVisible, voucherData, isTrialApplied, packageData, isBindingPriceVisible, priceOverride])

  const initPeriod = useMemo(() => {
    if (priceOverride) {
      return {
        period: priceOverride.initPeriod,
        numberOfPeriods: priceOverride.numberOfInitPeriods,
      }
    }
    if (isVoucherAppliedAndVisible) {
      return {
        period: voucherData.productPayment.initPeriod,
        numberOfPeriods: voucherData.productPayment.noOfTrialPeriods,
      }
    }
    if (isTrialApplied) {
      return {
        period: packageData.defaultProduct.initPeriod,
        numberOfPeriods: packageData.defaultProduct.numberOfInitPeriods,
      }
    }
    return undefined
  }, [isVoucherAppliedAndVisible, voucherData, isTrialApplied, packageData, priceOverride])

  const recurringPrice = formatPrice(packageData.defaultProduct.price)

  const initPriceText = useMemo(() => {
    if (isVoucherAppliedAndVisible || priceOverride) {
      if (initPeriod?.period === OrderPeriods.WEEK) {
        return t('subscription__voucher__price__week', {
          price: initPrice,
        })
      }
      return t('subscription__general__price', {
        price: initPrice,
      })
    }
    if (isBindingPriceVisible) {
      return t('subscription__general__price', {
        price: initPrice,
      })
    }
    if (isTrialApplied) {
      if (initPeriod?.period === OrderPeriods.WEEK) {
        return t('subscription__trial__price_week', {
          price: initPrice,
        })
      }
      return t('subscription__trial__price_month', {
        price: initPrice,
        duration: initPeriod?.numberOfPeriods ?? 1,
      })
    }
  }, [t, isVoucherAppliedAndVisible, isTrialApplied, initPrice, initPeriod, isBindingPriceVisible, priceOverride])

  const recurringPriceText = useMemo(() => {
    if (isTrialApplied && !isBindingPriceVisible) {
      return t('subscription__trial__price_recurring', {
        price: recurringPrice,
      })
    }
    if (showFromPrice && packageData.defaultBindingProduct) {
      return t('subscription__general__price_from', { price: formatPrice(packageData.defaultBindingProduct.initPrice) })
    }
    return t('subscription__general__price', {
      price: recurringPrice,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, isTrialApplied, recurringPrice, isBindingPriceVisible, showFromPrice])

  const isDefault = !isVoucherAppliedAndVisible && !isTrialApplied && !isBindingPriceVisible && !priceOverride

  if (isDefault) {
    return (
      <TypographyText nwColor="primary" nwVariant={large ? 'title2' : 'body1Strong'}>
        {recurringPriceText}
      </TypographyText>
    )
  }

  return (
    <Stack nwAlignItems="center">
      <PriceTexts
        isLarge={large}
        isStrikeThrough={isVoucherAppliedAndVisible || isBindingPriceVisible}
        nonHightlightedPrice={recurringPriceText}
        highlightedPrice={initPriceText}
      />
    </Stack>
  )
}

type PriceTextsProps = {
  isLarge: boolean
  isStrikeThrough: boolean
  nonHightlightedPrice: string
  highlightedPrice?: string
}

export const PriceTexts = ({ highlightedPrice, isLarge, nonHightlightedPrice, isStrikeThrough }: PriceTextsProps) => {
  return (
    <>
      <TypographyText nwColor="highlight" nwVariant={isLarge ? 'title2' : 'body1Strong'}>
        {highlightedPrice}
      </TypographyText>
      <TextOptionalStrikeThrough
        nwColor="tertiary"
        nwVariant={isLarge ? 'body1' : 'body4'}
        strikeThrough={isStrikeThrough}
      >
        {nonHightlightedPrice}
      </TextOptionalStrikeThrough>
    </>
  )
}
