import type { CSSObject } from 'styled-components'
import {
  breakpointList,
  color,
  fonts,
  generateResponsiveStyles,
  grid,
  radii,
  size,
  spacePx,
  typography,
} from '@nordic-web/ui-tokens'

type TypographyKey = keyof typeof typography

const responsiveTypography = Object.keys(typography).reduce(
  (acc, key) => {
    const typedKey = key as TypographyKey
    const style = typography[typedKey]

    acc[typedKey] = {
      lineHeight: style.lineHeight,
      fontWeight: style.fontWeight,
      ...('textTransform' in style && { textTransform: style.textTransform }),
      ...(Array.isArray(style.fontSize)
        ? generateResponsiveStyles('fontSize', style.fontSize)
        : { fontSize: style.fontSize }),
    }

    return acc
  },
  {} as Record<TypographyKey, CSSObject>
)

export const theme = {
  breakpoints: breakpointList,
  fonts,
  color,
  space: spacePx,
  radii,
  typography: responsiveTypography,
  fontWeightBold: 500,
  size,
  grid,
}

export type Theme = typeof theme
