import type { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { NotificationAlert } from '@nordic-web/ui-components'
import { tablet } from '@nordic-web/ui-styles'

const WrapComponent = styled.div(({ theme }) => ({
  paddingInline: theme.space(8),
  display: 'flex',
  justifyContent: 'center',
  [tablet]: {
    width: '100vw',
    paddingInline: theme.space(2),
  },
}))

export const CommonSubscriptionAlert = ({ children }: PropsWithChildren) => {
  return (
    <WrapComponent>
      <NotificationAlert nwVariant="warning">{children}</NotificationAlert>
    </WrapComponent>
  )
}
