import { useCallback } from 'react'
import { useAuthState } from '@/features/auth/context/auth-state-context'

export const useTokenRefreshOn401 = () => {
  const { refetchAccessToken, accessToken } = useAuthState()

  const retryWithTokenRefresh = useCallback(
    async <T>(fetchFunction: (token?: string) => Promise<T>): Promise<T> => {
      try {
        return await fetchFunction(accessToken)
      } catch (error: LegitimateAny) {
        if (error.status === 401) {
          const accessToken = await refetchAccessToken()

          if (!accessToken) {
            throw new Error('Failed to refresh access token')
          }

          return await fetchFunction(accessToken)
        }
        throw error
      }
    },
    [accessToken, refetchAccessToken]
  )

  return retryWithTokenRefresh
}
