import React from 'react'
import { useTranslations } from 'next-intl'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import {
  Button,
  ButtonGroup,
  FormInputPassword,
  Stack,
  TypographyText,
  useNotificationSnackbar,
} from '@nordic-web/ui-components'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { useAccountApi } from '@/features/auth/hooks/use-account-api'
import { useErrorMessage } from '@/features/auth/hooks/use-error-messages'
import { AuthModalPaths, ChildLockFormViews } from '@/features/auth/types'
import { useValidators } from '@/hooks/use-validators'

const FormFieldWrapper = styled.div({
  width: '100%',
})

type ResetCodeProps = {
  onClose: () => void
  email?: string
  isChangingPin?: boolean
}

type ResetCodeFormData = { password: string }

export const DisableWithPassword = ({ onClose, email, isChangingPin }: ResetCodeProps) => {
  const t = useTranslations()
  const { showSnackbarMessage } = useNotificationSnackbar()
  const { validationTexts } = useValidators()
  const { dispatch: modalDispatch } = useAuthModal()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ResetCodeFormData>()

  const { disableChildLockWithPasswordMutation: mutation } = useAccountApi()
  const isLoading = mutation.isPending
  const errorMessage = useErrorMessage(mutation.error)

  const onSubmit = (data: ResetCodeFormData) => {
    mutation.mutate({ password: data.password, enabled: false }, { onSuccess })
  }

  const onSuccess = () => {
    showSnackbarMessage({ text: t('snackbar__settings__child_lock_disabled') })
    onClose()
  }

  return (
    <form data-test="edit-pin-code-modal" name="resetPin" onSubmit={handleSubmit(onSubmit)}>
      <Stack nwGap={4}>
        <TypographyText nwVariant="body4">{email}</TypographyText>
        <FormFieldWrapper data-test="edit-pin-code-password-input">
          <FormInputPassword
            nwErrorMessage={errors.password?.message}
            nwLabel={t('child_lock__password')}
            validators={register('password', { required: validationTexts.required })}
          />
        </FormFieldWrapper>
        {!!errorMessage && (
          <TypographyText nwVariant="smallText" nwColor="error">
            {errorMessage}
          </TypographyText>
        )}
        <ButtonGroup nwAxis="y" nwFullWidth>
          <Button data-test="save-form" disabled={isLoading} name="submit" nwLoading={isLoading} type="submit">
            {t('general__continue')}
          </Button>
          <Button onClick={onClose} nwVariant="secondaryTransparent">
            {t('general__cancel')}
          </Button>
          <Button
            data-test="edit-pin-code-forgot-password-link"
            onClick={() =>
              modalDispatch({
                type: 'show-auth-modal',
                path: AuthModalPaths.FORGOT,
                pathParams: {
                  returnPath: AuthModalPaths.CHILDLOCK,
                  formView: isChangingPin
                    ? ChildLockFormViews.CHANGE_WITH_PASSWORD
                    : ChildLockFormViews.DISABLE_WITH_PASSWORD,
                },
              })
            }
            nwVariant="ghost"
          >
            {t('child_lock__recover_password')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  )
}
