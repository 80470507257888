import type { BindingSubscriptionAlertProps } from '@/components/subscription-alerts/binding-subscription-alert'
import { BindingSubscriptionAlert } from '@/components/subscription-alerts/binding-subscription-alert'
import { IapSubscriptionAlert } from '@/components/subscription-alerts/iap-subscription-alert'
import { TierChangeAlert } from '@/components/subscription-alerts/tier-change-alert'
import { useIap } from '@/features/packages/hooks/use-iap'

export const SubscriptionAlerts = ({ conversion }: BindingSubscriptionAlertProps) => {
  const { hasIap } = useIap()
  return (
    <>
      <IapSubscriptionAlert />
      {!hasIap && (
        <>
          <BindingSubscriptionAlert conversion={conversion} />
          <TierChangeAlert />
        </>
      )}
    </>
  )
}
