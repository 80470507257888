import { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { TypographyLink, TypographyText } from '@nordic-web/ui-components'
import { CommonSubscriptionAlert } from '@/components/subscription-alerts/common-subscription-alert'
import { CancelIapModal } from '@/features/conversion-pages/components/cancel-iap-modal/cancel-iap-modal'
import { useIap } from '@/features/packages/hooks/use-iap'

export const IapSubscriptionAlert = () => {
  const t = useTranslations()
  const { query, pathname } = useRouter()
  const [isIapModalOpen, setIsIapModalOpen] = useState<boolean>(false)
  const { hasIap } = useIap()

  useEffect(() => {
    if (query.cancel === 'iap') {
      setIsIapModalOpen(true)
    }
  }, [query])

  const iapQuery = { cancel: 'iap' }

  if (!hasIap) return null

  const alertValue = (
    <TypographyText nwVariant="body3">
      {t.rich('subscription__iap__deactivate_desc', {
        link: (link) => (
          <TypographyLink
            nwColor="primaryInverted"
            href={{ pathname, query: query.tierId ? { tierId: query.tierId, ...iapQuery } : iapQuery }}
          >
            {link}
          </TypographyLink>
        ),
      })}
    </TypographyText>
  )

  return (
    <>
      <CommonSubscriptionAlert>{alertValue}</CommonSubscriptionAlert>
      <CancelIapModal isOpen={isIapModalOpen} onClose={() => setIsIapModalOpen(false)} />
    </>
  )
}
