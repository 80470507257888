import { useTierChangeConfigurationQuery } from '@nordic-web/gql'
import { hasTierChangeEnabled } from '@/helpers/has-tier-change-enabled'

export const useTierChangeEnabled = () => {
  const result = useTierChangeConfigurationQuery({
    ssr: false,
  })

  return hasTierChangeEnabled(result)
}
