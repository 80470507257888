import type { ReactNode } from 'react'
import type { CSSObject } from 'styled-components'
import styled from 'styled-components'
import { desktop } from '@nordic-web/ui-styles'

export const labelVariants = [
  'airtime',
  'live',
  'reoccurring',
  'announcement',
  'remaining',
  'plus',
  'additionalInfo',
] as const
type LabelVariant = (typeof labelVariants)[number]

export type LabelPlacement = {
  nwPlacement?: 'default' | 'corner'
}

export type LabelProps = {
  nwVariant: LabelVariant
  children?: ReactNode
  groupStyling?: CSSObject
  className?: string
} & LabelPlacement

export type LabelPropsOptionals = Omit<LabelProps, 'nwVariant'>

const LabelContainer = styled.div<LabelProps>(({ nwVariant, nwPlacement = 'default', groupStyling = {}, theme }) => {
  const placementStyle = {
    default: {},
    corner: {
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: theme.radii.border_radius,
    },
  }
  const labelVariantsStyle = {
    airtime: {
      backgroundColor: theme.color.labels.airtime,
      color: theme.color.text.primaryInverted,
    },
    live: {
      backgroundColor: theme.color.labels.live,
      color: theme.color.text.primary,
    },
    reoccurring: {
      backgroundColor: theme.color.labels.reoccurring,
      color: theme.color.text.primary,
    },
    announcement: {
      backgroundColor: theme.color.labels.default,
      color: theme.color.text.primary,
    },
    remaining: {
      backgroundColor: theme.color.labels.remaining,
      color: theme.color.text.primaryInverted,
    },
    plus: {
      backgroundColor: theme.color.labels.plus,
      color: theme.color.text.primary,
      textTransform: 'none' as const,
    },
    additionalInfo: {
      backgroundColor: theme.color.surface.black60,
      color: theme.color.text.primary,
    },
  }

  return {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    paddingBlock: theme.space(1),
    paddingInline: theme.space(1.5),
    borderRadius: theme.radii.radiiX(2),
    [desktop]: {
      paddingBlock: theme.space(1.5),
      paddingInline: theme.space(2),
      borderRadius: theme.radii.border_radius_small,
      ...labelVariantsStyle[nwVariant],
      ...placementStyle[nwPlacement],
      ...groupStyling,
    },
    ...theme.typography.label,
    ...labelVariantsStyle[nwVariant],
    ...placementStyle[nwPlacement],
    ...groupStyling,
  }
})

const labelVariantContent: Partial<Record<LabelVariant, string>> = {
  live: '• Live',
}

export const Label = ({ children, ...props }: LabelProps) => {
  const { nwVariant } = props
  const content = labelVariantContent[nwVariant] || children
  return <LabelContainer {...props}>{content}</LabelContainer>
}
