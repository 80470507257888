import { useEffect } from 'react'
import Link from 'next/link'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import type { UrlObject } from 'url'
import { Button, TypographyText } from '@nordic-web/ui-components'
import { desktop } from '@nordic-web/ui-styles'
import { getPageName } from '@/helpers/get-page-name'
import { dataLayerPush } from '@/tracking/tracking'

const BannerContainer = styled(Link)<{
  $background: string
  $sticky: boolean
}>(({ $background, $sticky, theme }) => ({
  backdropFilter: 'blur(20px)',
  position: 'relative',
  background: $background,
  gap: theme.space(2),
  display: 'flex',
  flexDirection: 'row',
  borderRadius: theme.radii.border_radius_large,
  padding: theme.space(5),
  justifyContent: 'space-between',
  alignItems: 'center',
  overflow: 'hidden',
  '&:hover': {
    textDecoration: 'none',
  },
  backgroundColor: theme.color.surface.plus,
  [desktop]: {
    ...($sticky && {
      paddingLeft: theme.space(16),
      paddingRight: theme.space(6),
    }),
  },
}))

const BannerWrapper = styled.div<{ $sticky: boolean }>(({ $sticky, theme }) => ({
  ...($sticky && {
    position: 'fixed',
    zIndex: 1,
    bottom: theme.space(8),
    left: theme.space(4),
    right: theme.space(4),

    [desktop]: {
      bottom: theme.space(8),
      left: theme.space(16),
      right: theme.space(16),
    },
  }),
}))

type BannerProps = {
  background?: string
  text: string
  sticky?: boolean
  href: UrlObject & { query: unknown }
  tierId?: string
}

export const Banner = ({ background = 'rgba(38, 37, 36, 0.5)', text, href, sticky = false, tierId }: BannerProps) => {
  useEffect(() => {
    dataLayerPush({
      event: 'upsell_impression',
      page_name: getPageName(),
      upsell_item: 'banner',
      upsell_tier: tierId,
    })
  }, [tierId])

  const onBannerClicked = () => {
    dataLayerPush({
      event: 'upsell_click',
      page_name: getPageName(),
      upsell_item: 'banner',
      upsell_tier: tierId,
    })
  }

  const bannerContent = (
    <BannerWrapper $sticky={sticky} onClick={onBannerClicked}>
      <BannerContainer $background={background} href={href} $sticky={sticky}>
        <TypographyText nwColor="primary" nwVariant="info">
          {text}
        </TypographyText>
        <Button nwIconFirst="arrow-right" nwVariant="ghost" />
      </BannerContainer>
    </BannerWrapper>
  )

  if (sticky) {
    return createPortal(bannerContent, document.body)
  } else {
    return bannerContent
  }
}
