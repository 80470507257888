import { useAuthState } from '@/features/auth/context/auth-state-context'
import { usePrevious } from '@/hooks/use-previous'

/**
 * @returns This hook returns true if the login state has changed since the last render
 */
export const useHasLoginStateChanged = () => {
  const { refreshToken } = useAuthState()
  const previousRefreshToken = usePrevious(refreshToken)
  return previousRefreshToken !== undefined && refreshToken !== previousRefreshToken
}
