import React, { useEffect } from 'react'
import { useContinueWatchingPanelQuery } from '@nordic-web/gql'
import { Slider } from '@/components/slider/slider'
import { HideModuleSpacer, getSwipeModuleApiLimit } from '@/components/swipe-module/swipe-module'
import { PanelTitle } from '@/components/typography/panel-title'
import { ContinueWatchingCard } from './continue-watching-card'

type Props = {
  panel: {
    id: string
    title: string
  }
}

export const ContinueWatchingPanel = ({ panel }: Props) => {
  const {
    data,
    loading: isLoading,
    refetch,
    fetchMore,
  } = useContinueWatchingPanelQuery({
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      panelId: panel.id,
      offset: 0,
      limit: getSwipeModuleApiLimit(),
    },
  })

  const items = data?.panel?.__typename === 'ContinueWatchingPanel' ? data.panel.content.items : []
  const pageInfo = data?.panel?.__typename === 'ContinueWatchingPanel' ? data.panel.content.pageInfo : null
  const hasNextPage = !!pageInfo?.hasNextPage

  // This effect makes sure that the list is not empty when the user starts deleting items
  useEffect(() => {
    if (items.length < getSwipeModuleApiLimit() && hasNextPage) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, items])

  if (isLoading) {
    return null
  }

  if (items.length === 0 && !isLoading) {
    return <HideModuleSpacer />
  }

  return (
    <div data-test="keepwatching-module">
      <PanelTitle>{panel.title}</PanelTitle>

      <Slider
        fetchMoreData={() => {
          fetchMore({
            variables: {
              limit: getSwipeModuleApiLimit(),
              offset: pageInfo?.nextPageOffset,
            },
          })
        }}
        hasMoreItems={hasNextPage}
      >
        {items.map((item, idx) => (
          <ContinueWatchingCard key={idx} item={item} panelId={panel.id} />
        ))}
      </Slider>
    </div>
  )
}
