import type { ReactNode } from 'react'
import { createContext, useContext, useReducer } from 'react'
import type { AuthModalPaths, ChildLockFormViews, ForgotPasswordReturnPaths } from '@/features/auth/types'

type PathParams = {
  returnPath?: ForgotPasswordReturnPaths | null
  formView?: ChildLockFormViews | null
}

export type AuthModalState = {
  isOpen: boolean
  path?: AuthModalPaths
  pathParams?: PathParams
}

type Action =
  | {
      type: 'show-auth-modal'
      path: AuthModalPaths
      pathParams?: PathParams
    }
  | {
      type: 'close-auth-modal'
    }

type Dispatch = (action: Action) => void

export const AuthModalContext = createContext<{ state: AuthModalState; dispatch: Dispatch } | undefined>(undefined)

function AuthModalReducer(state: AuthModalState, action: Action) {
  switch (action.type) {
    case 'show-auth-modal': {
      return {
        ...state,
        isOpen: true,
        path: action.path,
        pathParams: action.pathParams,
      }
    }
    case 'close-auth-modal': {
      return {
        ...state,
        isOpen: false,
      }
    }
    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

const initialState: AuthModalState = {
  isOpen: false,
  path: undefined,
  pathParams: undefined,
}

function AuthModalProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(AuthModalReducer, initialState)

  const value = { state, dispatch }

  return <AuthModalContext.Provider value={value}>{children}</AuthModalContext.Provider>
}

function useAuthModal() {
  const context = useContext(AuthModalContext)
  if (context === undefined) {
    throw new Error('useAuthModal must be used within a AuthModalProvider')
  }
  return context
}

export { AuthModalProvider, useAuthModal }
