import { useRouter } from 'next/router'
import { paths } from '@/helpers/paths'

export type UpsellClickHandler = React.MouseEventHandler<HTMLElement>

export const useUpsellClickHandler = (tierId: string | undefined): UpsellClickHandler => {
  const router = useRouter()

  return (event) => {
    if (!tierId) return
    event.preventDefault()
    const upsellHref = paths.packages.urlString({ tierId, return: router.asPath })
    router.push(upsellHref)
  }
}
