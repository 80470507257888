import type { ReactNode } from 'react'
import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import { TypographyText } from '@nordic-web/ui-components'
import { MaxWidthContainerMd } from '@/components/max-width-container/max-width-container-md'

type UpgradeWithDiscountInfoProps = {
  discountedPrice: number
  bindingDisclaimer?: ReactNode
}

const Wrapper = styled(MaxWidthContainerMd)({
  textAlign: 'center',
})

export const UpgradeWithDiscountInfo = ({ discountedPrice, bindingDisclaimer }: UpgradeWithDiscountInfoProps) => {
  const t = useTranslations()
  return (
    <Wrapper>
      <div>
        <TypographyText nwColor="primary" nwVariant="body2">
          {t('subscription__checkout__upgrade__discount_price', { price: discountedPrice })}
        </TypographyText>
        <TypographyText nwColor="secondary" nwVariant="body2">
          {t('subscription__checkout__upgrade__discount_disclaimer')}
        </TypographyText>
      </div>
      {bindingDisclaimer && (
        <TypographyText nwVariant="body2" nwColor="secondary" nwTextAlign="center">
          {bindingDisclaimer}
        </TypographyText>
      )}
    </Wrapper>
  )
}
