import { useTranslations } from 'next-intl'
import Link from 'next/link'
import styled from 'styled-components'
import { Icon, TypographyText } from '@nordic-web/ui-components'
import { brandConfig } from '@/config/brand'
import { paths } from '@/helpers/paths'

const AcceptTermsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.space(3),
}))

const Checkbox = styled.div(({ theme }) => ({
  height: '24px',
  width: '24px',
  backgroundColor: theme.color.surface.white20,
  borderRadius: theme.radii.border_radius_small,
  position: 'relative',
}))

const CheckIconContainer = styled.div({
  height: '24px',
  position: 'absolute',
  top: 0,
  left: 0,
})

const RedLink = styled(Link)(({ theme }) => ({
  color: theme.color.text.secondary,
  textDecoration: 'underline',
}))

type AcceptTermsProps = {
  checked: boolean
  handleClick: () => void
}

export const AcceptTerms = ({ checked, handleClick }: AcceptTermsProps) => {
  const t = useTranslations()

  return (
    <AcceptTermsContainer>
      <Checkbox onClick={handleClick} data-testid="checkout-accept-terms">
        {checked && (
          <CheckIconContainer>
            <Icon nwVariant="check" />
          </CheckIconContainer>
        )}
      </Checkbox>
      <TypographyText nwVariant="body4" nwColor="secondary">
        {t.rich('subscription__checkout__upgrade__accept_terms', {
          link: (linkText) => (
            <RedLink href={paths.article.url({ articleId: brandConfig.gdprLinks.termsOfUse })} target="_blank">
              {linkText}
            </RedLink>
          ),
        })}
      </TypographyText>
    </AcceptTermsContainer>
  )
}
