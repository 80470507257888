import type { FieldMergeFunction, FieldPolicy, TypePolicy } from '@apollo/client'
import { InMemoryCache } from '@apollo/client'
import introspectionResult from '../generated/introspectionResult.generated'

type KeyArgs = FieldPolicy['keyArgs']

const fieldMergeFunction =
  (field = 'items'): FieldMergeFunction =>
  (existing, incoming, { args }) => {
    const offset = args?.input.offset

    // If we for example do pull to refresh, we dont want to keep the items not in this pagination window
    if (offset === 0) {
      return incoming
    }

    const limit = args?.input.limit
    const mergedItems = existing ? existing[field].slice(0) : []
    const end = offset + Math.min(limit, incoming[field]?.length)

    for (let i = offset; i < end; ++i) {
      mergedItems[i] = incoming[field][i - offset]
    }
    return {
      ...incoming,
      [field]: mergedItems,
    }
  }
// a d
// www.apollographql.com/docs/react/caching/cache-field-behavior
// You can see this implementation for inspiration: https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L28
function offsetLimitPagination(keyArgs: KeyArgs = false, fieldName = 'content', itemsFieldName?: string): TypePolicy {
  return {
    fields: {
      [fieldName]: {
        keyArgs,
        // This code makes it possible to fetch paginated queries with any offset, and merge the existing
        // items with the incoming ones and makes sure they are put at the correct offset
        merge: fieldMergeFunction(itemsFieldName),
      },
    },
  }
}

const merge = {
  merge: true,
}

// Incoming only
const dontMerge = {
  merge: false,
}

export function createInMemoryCache() {
  return new InMemoryCache({
    addTypename: true,
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          panel: (_, { args, toReference, cache }) =>  {
            if (!args?.id) return
            // This is a cache redirect to make the panel root query fetch its data from the cache if its already fetched
            // from for example the page.panels query. We dont know the typename of the panel so we need to check all possible
            // types of panels
            const cacheData = cache.extract()
            const cacheKeys = Object.keys(cacheData);
            const ref = cacheKeys.find((key) => key.endsWith(args.id))
            return ref ? toReference(ref): undefined
          },
          mediaIndex: merge,
          season: merge,
          favorites: dontMerge,
          search: {
            keyArgs: ['input', ['query', 'types']],
            merge: fieldMergeFunction(),
          },
          listSearch: {
            keyArgs: ['input', ['query', 'types', 'includeUpsell']],
            merge: fieldMergeFunction(),
          },
        },
      },
      Series: offsetLimitPagination(['input', ['types']], 'recommendations'),
      Movie: offsetLimitPagination(['input', ['types']], 'recommendations'),
      Clip: offsetLimitPagination(['input', ['types']], 'recommendations'),
      MediaIndex: offsetLimitPagination(['input', ['letterFilters', 'filterContentOnTier']], 'contentList'),
      MediaPanel: offsetLimitPagination(),
      ChannelPanel: offsetLimitPagination(),
      ClipsPanel: offsetLimitPagination(),
      EpisodesPanel: offsetLimitPagination(),
      LivePanel: offsetLimitPagination(),
      SportEventPanel: offsetLimitPagination(),
      ContinueWatchingPanel: offsetLimitPagination(),
      PagePanel: offsetLimitPagination(),
      Page: offsetLimitPagination(false, undefined, 'panels'),
      Season: offsetLimitPagination(['input', ['sortOrder']], 'episodes'),
      EliminationPoll: {
        fields: {
          options: dontMerge,
        },
      },
      SearchHistoryMutationSuccessResponse: merge,
      SearchHistoryResult: merge,
      DateTime: merge,
      Duration: merge,
      SeriesImages: merge,
      ChannelImages: merge,
      Country: {
        keyFields: ['name'],
      },
      EpgItem: {
        keyFields: ['broadcastId'],
      },
      Channel: dontMerge,
      MovieImages: merge,
      SportEventImages: merge,
      Synopsis: merge,
      PanelSearchData: merge,
      Image: merge,
      Misc: merge,
      EpisodeUpsell: merge,
      User: merge,
    },
  })
}
