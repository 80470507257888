import { useTierChangeConfigurationQuery } from '@nordic-web/gql'
import { CommonSubscriptionAlert } from '@/components/subscription-alerts/common-subscription-alert'
import { hasTierChangeEnabled } from '@/helpers/has-tier-change-enabled'

export const TierChangeAlert = () => {
  const result = useTierChangeConfigurationQuery({
    ssr: false,
  })

  const reasonText = result.data?.misc?.tierChangeConfiguration.reasonText

  if (!reasonText || hasTierChangeEnabled(result)) return null

  return <CommonSubscriptionAlert>{reasonText}</CommonSubscriptionAlert>
}
