import { Children, useEffect, useId, useRef, useState } from 'react'
import type { KeyboardEvent, PropsWithChildren, ReactNode } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { tablet } from '@nordic-web/ui-styles'
import { TypographyText } from '../../typography/typography-text/typography-text'
import { UtilHorizontalScrollArea } from '../../util/util-horizontal-scroll-area/util-horizontal-scroll-area'

type Tab = {
  id: string
  label: string
  /**
   * Extra stuff rendered after the label
   */
  actions?: ReactNode
  /**
   * If href is set, the tab will be rendered as a link
   */
  href?: string
  onClick?: () => void
  disabled?: boolean
}

export type NavigationTabsProps = PropsWithChildren<{
  /**
   * aria-label for the tablist
   */
  nwLabel?: string
  nwTabs: Tab[]
  nwInitActiveTab?: number
  nwOnChange?: (index: number) => void
}>

type TabRef = HTMLButtonElement | HTMLAnchorElement | null

/**
 * Tabs navigation with horizontal scroll
 * Depending on how it is used it follows this pattern https://www.w3.org/WAI/ARIA/apg/patterns/tabs/examples/tabs-manual/ when used with the same amount of tabpanels as tabs,
 * and this pattern https://github.com/w3c/aria-practices/issues/2310 when used with only one tabpanel.
 * Note: The first pattern is the preferred one.
 */
export const NavigationTabs = ({ nwLabel, nwTabs, nwInitActiveTab = 0, nwOnChange, children }: NavigationTabsProps) => {
  const [isRendered, setIsRendered] = useState(false)
  const [activeTab, setActiveTab] = useState(nwInitActiveTab ?? null)
  const [focusTab, setFocusTab] = useState(nwInitActiveTab ?? 0)
  const randomId = useId()
  const tabRefs = useRef<TabRef[]>([])
  const childrenCount = Children.count(children)
  const isSinglePanel = childrenCount === 1

  const handleClick = (index: number) => {
    setActiveTab(index)
    if (nwOnChange) {
      nwOnChange(index)
    }
    if (nwTabs?.[index]?.onClick) {
      nwTabs[index]?.onClick?.()
    }
  }

  useEffect(() => {
    if (nwInitActiveTab !== null) {
      setActiveTab(nwInitActiveTab)
    }
  }, [nwInitActiveTab])

  const handleFocus = (index: number) => {
    if (index === activeTab) {
      setFocusTab(index)
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowLeft') {
      e.preventDefault()
      setFocusTab((prevFocusTab) => (prevFocusTab - 1 >= 0 ? prevFocusTab - 1 : nwTabs.length - 1))
    } else if (e.key === 'ArrowRight') {
      e.preventDefault()
      setFocusTab((prevFocusTab) => (prevFocusTab + 1 < nwTabs.length ? prevFocusTab + 1 : 0))
    } else if (e.key === 'Home') {
      e.preventDefault()
      setFocusTab(0)
    } else if (e.key === 'End') {
      e.preventDefault()
      setFocusTab(nwTabs.length - 1)
    }
  }

  useEffect(() => {
    if (isRendered) {
      tabRefs.current[focusTab]?.focus()
    }
    setIsRendered(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusTab])

  useEffect(() => {
    if (activeTab !== null) {
      setFocusTab(activeTab)
    }
  }, [activeTab])

  return (
    <>
      <UtilHorizontalScrollArea nwFadeColor="transparent" nwFullWidth>
        <Nav>
          <Tablist role="tablist" aria-label={nwLabel} onKeyDown={handleKeyDown}>
            {nwTabs.map((tab, i) => {
              const isActive = i === activeTab
              return (
                <Tab
                  as={tab.href ? Link : 'button'}
                  key={tab.id}
                  href={tab.href ? tab.href : undefined}
                  onClick={() => handleClick(i)}
                  type={tab.href ? undefined : 'button'}
                  ref={(ref: TabRef) => {
                    tabRefs.current[i] = ref
                  }}
                  onFocus={() => handleFocus(i)}
                  draggable={false}
                  scroll={false}
                  aria-controls={
                    !isSinglePanel || (isSinglePanel && i === activeTab) ? `tabpanel-${randomId}-${i}` : undefined
                  }
                  aria-expanded={isSinglePanel ? undefined : i === activeTab ? 'true' : 'false'}
                >
                  <Label
                    nwVariant={isActive ? 'tabActive' : 'tab'}
                    as="span"
                    $active={isActive}
                    $disabled={tab.disabled}
                  >
                    {tab.label} {tab.actions}
                  </Label>
                </Tab>
              )
            })}
          </Tablist>
        </Nav>
      </UtilHorizontalScrollArea>

      {Children.map(children, (panel, i) => {
        return (
          <Tabpanel
            role="tabpanel"
            aria-label={isSinglePanel ? nwTabs[activeTab]?.label : nwTabs[i]?.label}
            $active={isSinglePanel || i === activeTab}
            id={`tabpanel-${randomId}-${i}`}
          >
            {panel}
          </Tabpanel>
        )
      })}
    </>
  )
}

const Nav = styled.nav(({ theme }) => ({
  paddingBlock: theme.space(5),
}))

const Tablist = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

const Tab = styled.button(({ theme }) => ({
  appearance: 'none',
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
  fontFamily: theme.fonts.body,
  color: theme.color.text.primary,
  '&:first-child': {
    paddingInlineStart: 0,
  },
  '&:last-child': {
    paddingInlineEnd: 0,
  },
  paddingInline: theme.space(3),
  [tablet]: {
    paddingInline: theme.space(6),
  },
}))

const Label = styled(TypographyText)<{ $active?: boolean; $disabled?: boolean }>(
  ({ theme, $active = false, $disabled = false }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.space(1),
    paddingBlockEnd: 8,
    ...($disabled && {
      opacity: 0.5,
    }),
    '&::after': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      background: theme.color.base.primaryLight,
      content: '" "',
      height: 4,
      opacity: $active ? 1 : 0,
      transformOrigin: 'left center',
      transform: `scaleX(${$active ? 1 : 0})`,
      transition: 'all 200ms cubic-bezier(0.2, 0.46, 0.45, 0.94) 0s',
    },
    '*:hover > &::after': {
      opacity: 1,
      transform: 'scaleX(1)',
    },
  })
)

const Tabpanel = styled.div<{ $active: boolean }>(({ $active }) => ({
  display: $active ? 'contents' : 'none',
}))
