import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import { type Package, type VoucherSuccess } from '@nordic-web/rest-codegen/generated/subscription'
import { Stack, TypographyText } from '@nordic-web/ui-components'
import { OrderPeriods } from '@/features/checkout/types'
import { VoucherBadge } from '@/features/voucher/components/voucher-badge'

type VoucherInfoTextProps = {
  period?: string
  duration: number
  packageTitle?: string
  bindingDuration?: number
  isRecurring: boolean
}

type VoucherInfoProps = {
  voucherData: VoucherSuccess
  packageData: Package
  resetVoucher: () => void
}

const VoucherInfoWrapper = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.color.border['25'],
  borderRadius: theme.radii.border_radius,
  padding: theme.space(4),
}))

const VoucherInfoText = ({
  period = 'P1M',
  duration,
  packageTitle,
  bindingDuration,
  isRecurring,
}: VoucherInfoTextProps) => {
  const t = useTranslations()

  const getInfoText = () => {
    if (period === OrderPeriods.WEEK) {
      return t.rich('subscription__voucher__disclaimer__week')
    }
    if (bindingDuration) {
      return t.rich('subscription__voucher__disclaimer__binding', {
        duration,
        packageTitle,
        bindingDuration,
      })
    }
    if (isRecurring) {
      return t('subscription__voucher__info_recurring_desc')
    }
    return t.rich('subscription__voucher_info__disclaimer', {
      duration,
    })
  }

  return (
    <TypographyText nwVariant="body3" nwColor="secondary">
      {getInfoText()}
    </TypographyText>
  )
}

/**
 * @deprecated
 */
export const VoucherInfo = ({ voucherData, packageData, resetVoucher }: VoucherInfoProps) => {
  const isRecurring = (voucherData.discountFractionRecurring ?? 0) > 0
  return (
    <VoucherInfoWrapper nwGap={4}>
      <VoucherBadge
        handleRemove={resetVoucher}
        voucherDiscount={{
          type: voucherData.discountType,
          value:
            voucherData.discountType === 'AMOUNT'
              ? packageData.defaultProduct.price - voucherData.discountedPrice
              : voucherData.discountFraction * 100,
        }}
        voucherDuration={voucherData.productPayment.noOfTrialPeriods ?? 1}
        period={voucherData.productPayment.initPeriod ?? 'P1M'}
        isRecurring={isRecurring}
      />
      <VoucherInfoText
        period={voucherData.productPayment.initPeriod ?? 'P1M'}
        duration={voucherData.productPayment.noOfTrialPeriods ?? 1}
        packageTitle={packageData.title}
        bindingDuration={voucherData.product.minimumPeriods ?? 0}
        isRecurring={isRecurring}
      />
      <TypographyText nwVariant="body3" nwColor="tertiary">
        {voucherData.code}
      </TypographyText>
    </VoucherInfoWrapper>
  )
}
