import type { FC, PropsWithChildren } from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useVideoQuery } from '@nordic-web/gql'
import { Button, ButtonGroup, CircleSpinner } from '@nordic-web/ui-components'
import { isFuture } from '@nordic-web/utils/date'
import { mapEpgMedia } from '@nordic-web/utils/epg'
import { isTypeInUnion } from '@nordic-web/utils/typescript'
import { StandardMetaPropertyList } from '@/components/meta-property-list/standard-meta-property-list'
import { MediaUpgradeBanner } from '@/features/cdp/components/media-upgrade-banner'
import { SharedDetails } from '@/features/channel-epg-panel/components/details-dialog/shared-details'
import type { ChannelEpgBase } from '@/features/channel-epg-panel/components/details-dialog/types'
import { FavoriteButton } from '@/features/favorite/favorite-button'
import { getParentInfo } from '@/helpers/get-parent-info'
import { getVideoTitle } from '@/helpers/get-video-title'
import { paths } from '@/helpers/paths'

export const MediaDetails: FC<PropsWithChildren<ChannelEpgBase>> = ({ channel, epgItem, children }) => {
  const t = useTranslations()
  const epgMedia = mapEpgMedia(epgItem)

  // This will never occur, but we need to check for it to satisfy TypeScript
  if (!epgMedia?.id) throw new Error('Missing media id')

  const { data, loading: isLoading } = useVideoQuery({
    ssr: false,
    variables: {
      id: epgMedia.id,
    },
  })

  const media = data?.media

  if (isLoading) return <CircleSpinner nwPaddingTop />

  if (!isTypeInUnion(media, 'Episode', 'Movie', 'SportEvent')) return null

  const title = getVideoTitle(media)
  const parentInfo = getParentInfo(media)
  const playableFrom = media.playableFrom?.isoString
  const isUpcoming = playableFrom ? isFuture(playableFrom) : false

  return (
    <SharedDetails
      channel={channel}
      title={title}
      epgItem={epgItem}
      metaSection={<StandardMetaPropertyList {...media} />}
      synopsis={media.synopsis.medium}
      image={media.images.main16x9.sourceEncoded}
    >
      <ButtonGroup nwMobileAxis="y">
        {!media.upsell && (
          <Link href={paths.video.urlString({ assetId: media.id, slug: media.slug })} passHref legacyBehavior>
            <Button
              as="a"
              disabled={isUpcoming}
              nwIconFirst={isUpcoming ? 'player-play-outlined' : 'player-play-filled'}
              nwVariant="secondaryTransparent2"
            >
              {isUpcoming
                ? t.rich('general__available_at', { date: media.playableFrom?.readableDateTime })
                : t('general__play_cta')}
            </Button>
          </Link>
        )}
        {parentInfo && (
          <Link href={paths.program.urlString({ id: parentInfo.id, slug: parentInfo.slug })} passHref legacyBehavior>
            <Button nwIconFirst="alert-info-outlined" nwVariant="secondaryTransparent2" as="a">
              {t('general__program_page_cta')}
            </Button>
          </Link>
        )}
        {media.__typename === 'Movie' && (
          <FavoriteButton nwVariant="secondaryTransparent2" mediaId={media.id} mediaType={media.__typename} />
        )}
      </ButtonGroup>

      {media.upsell && (
        <MediaUpgradeBanner
          tierName={media.upsell.tierName}
          title={title}
          tierId={media.upsell.tierId}
          sticky={false}
        />
      )}

      {children}
    </SharedDetails>
  )
}
