import type { ReactElement, ReactNode } from 'react'
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { ApolloProvider } from '@apollo/client'
import { useQueryClient } from '@tanstack/react-query'
import { refreshAccessTokenKey } from '@/features/auth/context/auth-state-context'
import { getRefreshTokenFromCookie } from '@/features/auth/tokens/token-utils'
import { initApollo } from '@/lib/apollo/init-apollo'

type Props = {
  stateFromServer: NormalizedCacheObject
  children: ReactNode
  apolloClient?: ApolloClient<NormalizedCacheObject>
}

export const ApolloProvidersWrapper = ({ stateFromServer, children, apolloClient }: Props): ReactElement => {
  const queryClient = useQueryClient()
  // Either use the client that is from the server, or initialize a new one
  const client =
    apolloClient ||
    initApollo(() => {
      const refreshToken = getRefreshTokenFromCookie()
      if (refreshToken) {
        try {
          return queryClient.fetchQuery<string>({ queryKey: refreshAccessTokenKey(refreshToken) })
        } catch (error) {
          console.error('Error fetching access token', error)
        }
      }
    }, stateFromServer)
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
