import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import { Icon, Stack, TypographyText } from '@nordic-web/ui-components'

const Wrapper = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.color.status.error,
  borderRadius: theme.radii.border_radius,
  padding: theme.space(4),
}))

const TitleWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

type VoucherErrorProps = { resetVoucher: () => void }

export const VoucherError = ({ resetVoucher }: VoucherErrorProps) => {
  const t = useTranslations()
  return (
    <Wrapper nwGap={1}>
      <TitleWrapper>
        <TypographyText nwVariant="packageCardBody" nwColor="primary">
          {t('subscription__voucher_error__not_valid')}
        </TypographyText>
        <Icon nwVariant="close-x-filled" nwClickable onClick={resetVoucher} />
      </TitleWrapper>
      <TypographyText nwVariant="packageCardBody" nwColor="secondary">
        {t('subscription__voucher_error__description')}
      </TypographyText>
    </Wrapper>
  )
}
