import React, { useMemo } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import type { Package } from '@nordic-web/rest-codegen/generated/subscription'
import { Button, CardTertiaryText } from '@nordic-web/ui-components'
import { BRAND, Brand } from '@/config/brand'
import { useCheckoutState } from '@/features/checkout/context/checkout-state-context'
import { useSubscription } from '@/features/checkout/hooks/use-subscription'
import { voucherIsApplied } from '@/features/checkout/upgrade'
import type { PriceOverrideProps } from '@/features/packages/components/package-price'
import { paths } from '@/helpers/paths'
import { stringFromQueryParam } from '@/utils/query-string'
import { PackageCardCommon } from './package-card-common'
import { PackageFeatureList } from './package-feature-list'

type PackageCardProps = {
  packageData: Package
  showReadMoreButton?: boolean
  fullWidth?: boolean
  priceOverride?: PriceOverrideProps
  isAcceptCancellationOfferCard?: boolean
}

export const PackageCardMedium = ({
  packageData,
  showReadMoreButton,
  fullWidth,
  priceOverride,
  isAcceptCancellationOfferCard = false,
}: PackageCardProps) => {
  const t = useTranslations()
  const router = useRouter()
  const { isCheckingOutWithBinding, productDetails, conversionType } = useCheckoutState()
  const {
    subscriptionState: { data: subscriptionState },
    voucher: { data: voucherData },
  } = useSubscription()

  const shouldShowDisclaimers = !voucherIsApplied(voucherData, packageData.vimondId)
  const shouldShowTrialDisclaimer = shouldShowDisclaimers && !isCheckingOutWithBinding && packageData.hasTrial
  const shouldShowBindingDisclaimer =
    (conversionType === 'UPGRADE' &&
      shouldShowDisclaimers &&
      isCheckingOutWithBinding &&
      !!packageData.defaultBindingProduct) ||
    !!priceOverride?.numberOfBindingPeriods
  // Child profiles are available for AVOD in MTV, so this is only needed for TV4
  const shouldShowChildProfilesDisclaimer =
    conversionType === 'CANCEL' && BRAND === Brand.TV4 && !isAcceptCancellationOfferCard

  const numberOfBindingPeriodsOffered = useMemo(() => {
    if (priceOverride) {
      return priceOverride.numberOfBindingPeriods
    }
    if (subscriptionState?.active?.isCurrentlyBoundToThisProduct) {
      return (
        subscriptionState.active.bindingMonthsLeft ??
        (productDetails?.numberOfInitPeriods ?? 12) - subscriptionState.active.activePeriods
      )
    }
    return productDetails?.numberOfInitPeriods ?? 12
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionState, productDetails])

  return (
    <PackageCardCommon
      packageData={{ ...packageData, isActive: false, isFuture: false, isCtaButtonDisabled: false, isFree: false }}
      size="md"
      fullWidth={fullWidth}
      priceOverride={priceOverride}
    >
      <PackageFeatureList features={packageData.features.slice(0, 3)} />
      {shouldShowBindingDisclaimer && (
        <CardTertiaryText>
          {t('subscription__terms__binding', { duration: numberOfBindingPeriodsOffered })}
        </CardTertiaryText>
      )}
      {shouldShowTrialDisclaimer && <CardTertiaryText>{t('subscription__trial__disclaimer')}</CardTertiaryText>}
      {shouldShowChildProfilesDisclaimer && (
        <CardTertiaryText>{t('subscription__cancel__child_profiles_disclaimer')}</CardTertiaryText>
      )}
      {showReadMoreButton && (
        <Button
          nwVariant="secondaryTransparent"
          nwLayout="full-width"
          onClick={() =>
            router.push(
              paths.packages.urlString({
                tierId: packageData.tierId,
                return: stringFromQueryParam(router.query?.return),
              })
            )
          }
        >
          {t('general__read_more')}
        </Button>
      )}
    </PackageCardCommon>
  )
}
