import React, { useId } from 'react'
import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import type { UseFormRegisterReturn } from 'react-hook-form'
import styled from 'styled-components'
import { TypographyText } from '../../typography/typography-text/typography-text'
import { FormInputBase } from '../_base/form-input-base/form-input-base'
import { FormValidationMessage } from '../form-validation-message/form-validation-message'

type Size = 'default' | 'small'

export type FormInputCheckboxProps = {
  nwLabel: ReactNode
  nwErrorMessage?: string
  nwSize?: Size
  /**
   * Minimum requirement is to pass the name to the register function from react-hook-form: `validators={register('input-name')}`
   */
  validators: UseFormRegisterReturn
} & Omit<ComponentPropsWithoutRef<'input'>, 'name' | 'type'>

export const FormInputCheckbox = ({
  nwLabel,
  nwErrorMessage,
  nwSize,
  validators,
  ...forwardProps
}: FormInputCheckboxProps) => {
  const randomId = useId()
  const id = forwardProps.id ?? `form-input-checkbox-${randomId}`

  return (
    <Wrapper>
      <Input
        {...validators}
        {...forwardProps}
        id={id}
        type="checkbox"
        aria-invalid={nwErrorMessage ? 'true' : 'false'}
      />
      <Label as="label" htmlFor={id} nwVariant={nwSize === 'small' ? 'body4' : 'body2'} nwLabelSize={nwSize}>
        {nwLabel}
      </Label>
      <StyledFormValidationMessage nwMessage={nwErrorMessage} />
    </Wrapper>
  )
}

const Wrapper = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `${theme.space(6)} auto`,
  gridTemplateRows: '1fr max-content',
  columnGap: theme.space(4),
  ...theme.typography.info,
}))

const StyledFormValidationMessage = styled(FormValidationMessage)({
  gridRow: 2,
  gridColumn: '1 / -1',
})

const Label = styled(TypographyText)<{ nwLabelSize?: Size }>(({ theme, nwLabelSize }) => ({
  gridRow: 1,
  gridColumn: 2,
  alignSelf: 'center',
  cursor: 'pointer',
  ...(nwLabelSize === 'small' && {
    color: theme.color.text.secondary,
  }),
}))

const Input = styled(FormInputBase)(({ theme }) => ({
  gridRow: 1,
  gridColumn: 1,
  appearance: 'none',
  height: 24,
  width: 24,
  cursor: 'pointer',

  '&:active': {
    background: theme.color.form.active,
  },

  '&:checked': {
    backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTguMTM2NCAwLjM2MzU5MkMxOC42MjEyIDAuODQ4MzgxIDE4LjYyMTIgMS42MzQzOCAxOC4xMzY0IDIuMTE5MTdMNi43MDY5IDEzLjU0ODdMMC44NjM1OTIgNy43MDUzN0MwLjM3ODgwMyA3LjIyMDU4IDAuMzc4ODAzIDYuNDM0NTkgMC44NjM1OTEgNS45NDk4QzEuMzQ4MzggNS40NjUwMSAyLjEzNDM4IDUuNDY1MDEgMi42MTkxNyA1Ljk0OThMNi43MDY5IDEwLjAzNzVMMTYuMzgwOCAwLjM2MzU5MUMxNi44NjU2IC0wLjEyMTE5NyAxNy42NTE2IC0wLjEyMTE5NyAxOC4xMzY0IDAuMzYzNTkyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '70%',
  },
}))
