import type { ParsedUrlQuery } from 'querystring'
import { isInternalPath } from '@/utils/is-internal-path'
import { stringFromQueryParam } from '@/utils/query-string'

// We want to avoid external links, and jaascript:alert(1) etc
export const getSafeReturnPathFromParam = (query: ParsedUrlQuery) => {
  const returnHref = stringFromQueryParam(query.return)
  if (isInternalPath(returnHref)) {
    return returnHref
  }
}
