import React, { createContext, useContext } from 'react'
import type { ControlProps, GroupBase, OptionProps, Props as ReactSelectProps } from 'react-select'
import ReactSelect, { components } from 'react-select'
import styled, { useTheme } from 'styled-components'
import { FormValidationMessage } from '../form-validation-message/form-validation-message'

export type FormInputDropdownOption = { label: string; value: string }

const TestIdContext = createContext<string | undefined>(undefined)
const LabelContext = createContext<string | undefined>('')

type OwnProps = {
  nwErrorMessage?: string
  nwLabel?: string
  /**
   * Prefixes data-testid attributes if set
   */
  nwTestId?: string
}

export const FormInputDropdown = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  nwErrorMessage,
  nwLabel,
  nwTestId,
  options,
  ...forwardProps
}: ReactSelectProps<Option, IsMulti, Group> & OwnProps) => {
  const theme = useTheme()
  return (
    <TestIdContext.Provider value={nwTestId}>
      <LabelContext.Provider value={nwLabel}>
        <Wrapper>
          <ReactSelect
            components={{ Control, Option }}
            isSearchable={false}
            options={options}
            placeholder=" "
            unstyled
            {...forwardProps}
            styles={{
              container: () => ({
                zIndex: 10,
                height: theme.space(12),
                background: theme.color.form.default,
                borderRadius: theme.radii.border_radius_small,
                border: `1px solid transparent`,
                ...(nwErrorMessage
                  ? {
                      borderColor: theme.color.border.error,
                    }
                  : {
                      '&:hover, &:focus-within': {
                        borderColor: theme.color.form.borderFocus,
                        background: theme.color.form.active,
                      },
                    }),
              }),
              control: (base, _) => ({
                ...base,
                padding: theme.space(3),
                height: theme.space(12),
                cursor: 'pointer',
              }),
              menu: () => ({
                marginBlockStart: theme.space(2),
                borderRadius: theme.radii.border_radius_small,
              }),
              menuList: (base, _) => ({
                ...base,
                background: theme.color.surface.white15,
                borderRadius: theme.radii.border_radius_small,
                paddingBlock: theme.space(4),
                backdropFilter: 'blur(40px)',
              }),
              option: (_, state) => ({
                padding: theme.space(3),
                cursor: 'pointer',
                ...(state.isFocused && { background: theme.color.form.active }),
              }),
              valueContainer: () => ({
                transform: nwLabel ? `translateY(${theme.space(2)})` : 'none',
              }),
              indicatorsContainer: () => ({
                alignSelf: 'baseline',
                paddingLeft: theme.space(4),
              }),
            }}
          />
          <StyledFormValidationMessage nwMessage={nwErrorMessage} />
        </Wrapper>
      </LabelContext.Provider>
    </TestIdContext.Provider>
  )
}

const Control = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  ...props
}: ControlProps<Option, IsMulti, Group>) => {
  const testId = useContext(TestIdContext)
  const label = useContext(LabelContext)

  return (
    <components.Control {...props}>
      <ControlInner>
        {label && (
          <Label data-testid={testId ? `${testId}-control` : null} isActive={props.hasValue}>
            {label}
          </Label>
        )}
        <div>{children}</div>
      </ControlInner>
    </components.Control>
  )
}

const Option = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  ...props
}: OptionProps<Option, IsMulti, Group>) => {
  const testId = useContext(TestIdContext)
  return (
    <components.Option {...props}>
      <span data-testid={testId ? `${testId}-option` : null} />
      {children}
    </components.Option>
  )
}

const Label = styled.label<{ isActive?: boolean }>(({ theme, isActive }) => ({
  gridRow: 1,
  gridColumn: 1,
  pointerEvents: 'none',
  ...theme.typography.body4,
  transformOrigin: 'top left',
  transition: 'transform 0.12s ease-out',
  alignSelf: 'self-start',
  ...(isActive && {
    transform: 'scale(.85) translateY(-50%)',
    opacity: 0.5,
  }),
}))

const ControlInner = styled.div({
  flex: 1,
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '1fr',
  '> *': {
    gridColumn: 1,
    gridRow: 1,
  },
  '> div': {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const StyledFormValidationMessage = styled(FormValidationMessage)({
  gridRow: 2,
  gridColumn: 1,
})

const Wrapper = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr max-content',
  ...theme.typography.info,
}))
