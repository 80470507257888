import type { NextRouter } from 'next/router'
import { paths } from '@/helpers/paths'

export const isOnAnyProfilePage = (router: NextRouter) => {
  return (
    paths.selectProfile.isActive(router) ||
    paths.editProfiles.isActive(router) ||
    paths.editProfile.isActive(router) ||
    paths.createProfile.isActive(router) ||
    paths.deleteProfile.isActive(router)
  )
}
