import { useEffect, useMemo } from 'react'
import { useTranslations } from 'next-intl'
import type { Conversions } from '@nordic-web/rest-codegen/generated/subscription'
import { CommonSubscriptionAlert } from '@/components/subscription-alerts/common-subscription-alert'
import { useSubscription } from '@/features/checkout/hooks/use-subscription'
import { formatDate } from '@/utils/date'

export type BindingSubscriptionAlertProps = {
  conversion?: Conversions
}

export const BindingSubscriptionAlert = ({ conversion }: BindingSubscriptionAlertProps) => {
  const {
    subscriptionState: { data: subscription },
    subscriptionPackageDetails: { data: packageData, refetch: fetchPackageData },
  } = useSubscription()
  const t = useTranslations()

  const isBoundToSubscription = subscription?.active?.isCurrentlyBoundToThisProduct

  useEffect(() => {
    if (isBoundToSubscription && !packageData) {
      fetchPackageData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPackageData, isBoundToSubscription])

  const bindingEndDate = subscription?.active?.earliestEndDate
    ? formatDate(subscription.active.earliestEndDate, { day: '2-digit', month: 'long', year: 'numeric' })
    : null

  const disclaimerTranslationKey = useMemo(() => {
    if (conversion === 'DOWNGRADE') {
      return 'subscription__binding__disclaimer_downgrade'
    }
    if (conversion === 'UPGRADE') {
      return 'subscription__binding__disclaimer_upgrade'
    }
    return 'subscription__binding__disclaimer'
  }, [conversion])

  if (!isBoundToSubscription) return null

  return (
    <CommonSubscriptionAlert>
      {t(disclaimerTranslationKey, {
        packageName: packageData?.active?.title,
        date: bindingEndDate,
      })}
    </CommonSubscriptionAlert>
  )
}
