import { useCallback, useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { Button, Dialog, FormInput, TypographyText } from '@nordic-web/ui-components'
import { useSubscription } from '@/features/checkout/hooks/use-subscription'

type VoucherErrors = 'not_valid' | 'existing_customer' | 'expired' | 'already_used' | 'wrong_package'

const DEFAULT_VOUCHER_ERROR: VoucherErrors = 'not_valid'

type FormInputs = {
  code: string
}

type ModalProps = {
  isOpen: boolean
  setIsOpen: (arg: boolean) => void
  resetVoucher: () => void
  entitlementId?: string
}

export const VoucherModal = ({ isOpen, setIsOpen, resetVoucher, entitlementId }: ModalProps) => {
  const router = useRouter()
  const t = useTranslations()
  const { handleSubmit, register, reset } = useForm<FormInputs>()

  const {
    voucher: { refetch: getVoucher, isFetching: isVoucherFetching, data: voucherData, error: voucherError },
  } = useSubscription(entitlementId)

  const [voucherErrorMessage, setVoucherErrorMessage] = useState<VoucherErrors>()

  const handleClose = useCallback(() => {
    setVoucherErrorMessage(undefined)
    setIsOpen(false)
  }, [setIsOpen])

  const handleCancel = useCallback(() => {
    resetVoucher()
    handleClose()
  }, [resetVoucher, handleClose])

  useEffect(() => {
    if ((voucherData && !voucherData.valid) || voucherError) {
      setVoucherErrorMessage((voucherData?.failureReason as VoucherErrors) ?? DEFAULT_VOUCHER_ERROR)
    } else if (voucherData?.valid) {
      handleClose()
    } else {
      // If no voucher data, make sure error is empty
      setVoucherErrorMessage(undefined)
    }
  }, [voucherData, voucherError, handleClose])

  const onSubmit = () => {
    getVoucher()
  }

  useEffect(() => {
    if (isOpen) {
      reset({ code: '' })
    }
  }, [isOpen, reset])

  return (
    <Dialog nwVariant="drawer" nwInitOpen={isOpen} onClose={handleClose}>
      <Dialog.Top />
      <Dialog.DrawerBody>
        <>
          <TypographyText nwVariant="title2" nwTextAlign="center">
            {t('subscription__voucher_modal_title')}
          </TypographyText>
          <TypographyText nwVariant="body3" nwColor="secondary">
            {t('subscription__voucher_form_desc')}
          </TypographyText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              nwLabel={t('subscription__voucher_form_input')}
              onChange={(e) => {
                if (voucherErrorMessage) {
                  setVoucherErrorMessage(undefined)
                }
                router.replace({
                  pathname: router.pathname,
                  query: { ...router.query, voucherCode: e.target.value },
                })
              }}
              nwErrorMessage={
                voucherErrorMessage ? t(`subscription__voucher_error__${voucherErrorMessage}`) : undefined
              }
              validators={register('code')}
            />
          </form>
          <Button onClick={onSubmit} disabled={isVoucherFetching || !router.query.voucherCode || !!voucherErrorMessage}>
            {t('general__continue')}
          </Button>
          <Button nwVariant="ghost" onClick={handleCancel}>
            {t('general__cancel')}
          </Button>
        </>
      </Dialog.DrawerBody>
    </Dialog>
  )
}
