import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { voucherQueryKey } from '@/features/checkout/hooks/use-subscription'

export const useResetVoucher = () => {
  const router = useRouter()
  const queryClient = useQueryClient()

  const resetVoucher = useCallback(() => {
    const query = { ...router.query }
    delete query.voucherCode

    router.replace({
      pathname: router.pathname,
      query,
    })
    queryClient.removeQueries({ queryKey: voucherQueryKey })
  }, [router, queryClient])

  return resetVoucher
}
