import type { SwiperOptions } from 'swiper'
import { DESKTOP, EXTRA_LARGE_DESKTOP, LARGE_DESKTOP, TABLET } from '@nordic-web/ui-tokens'

const SMALL_MARGIN = 8
const MEDIUM_MARGIN = 16
const LARGE_MARGIN = 20

type Breakpoints = {
  0: SwiperOptions
  [TABLET]: SwiperOptions
  [DESKTOP]: SwiperOptions
  [LARGE_DESKTOP]: SwiperOptions
  [EXTRA_LARGE_DESKTOP]: SwiperOptions
}

const margins = {
  0: SMALL_MARGIN,
  [TABLET]: MEDIUM_MARGIN,
  [DESKTOP]: MEDIUM_MARGIN,
  [LARGE_DESKTOP]: LARGE_MARGIN,
  [EXTRA_LARGE_DESKTOP]: LARGE_MARGIN,
}

const withMargins = (breakpoints: Breakpoints) => {
  return Object.entries(breakpoints).reduce((acc, [key, value]) => {
    const numberKey = Number(key)
    return {
      ...acc,
      [key]: {
        ...value,
        spaceBetween: margins[numberKey as keyof typeof margins],
      },
    }
  }, {}) as Breakpoints
}

export const defaultBreakpoints: Breakpoints = withMargins({
  [0]: {
    slidesPerView: 1.2,
    slidesPerGroup: 1,
  },
  [TABLET]: {
    slidesPerView: 2,
    slidesPerGroup: 2,
  },
  [DESKTOP]: {
    slidesPerView: 3,
    slidesPerGroup: 3,
  },
  [LARGE_DESKTOP]: {
    slidesPerView: 4,
    slidesPerGroup: 4,
  },
  [EXTRA_LARGE_DESKTOP]: {
    slidesPerView: 5,
    slidesPerGroup: 5,
  },
})

export const denseBreakpoints: Breakpoints = withMargins({
  [0]: {
    slidesPerView: 2.25,
    slidesPerGroup: 2,
  },
  [TABLET]: {
    slidesPerView: 3.25,
    slidesPerGroup: 3,
  },
  [DESKTOP]: {
    slidesPerView: 5,
    slidesPerGroup: 5,
  },
  [LARGE_DESKTOP]: {
    slidesPerView: 5,
    slidesPerGroup: 5,
  },
  [EXTRA_LARGE_DESKTOP]: {
    slidesPerView: 6,
    slidesPerGroup: 6,
  },
})

export const packageBreakpoints: Breakpoints = withMargins({
  [0]: {
    slidesPerView: 1.2,
    slidesPerGroup: 1,
  },
  [TABLET]: {
    slidesPerView: 3.1,
    slidesPerGroup: 2,
  },
  [DESKTOP]: {
    slidesPerView: 4.1,
    slidesPerGroup: 4,
  },
  [LARGE_DESKTOP]: {
    slidesPerView: 5.1,
    slidesPerGroup: 5,
  },
  [EXTRA_LARGE_DESKTOP]: {
    slidesPerView: 6.1,
    slidesPerGroup: 6,
  },
})

export const liveCardBreakpoints: Breakpoints = withMargins({
  [0]: {
    slidesPerView: 1.2,
    slidesPerGroup: 1,
  },
  [TABLET]: {
    slidesPerView: 1.2,
    slidesPerGroup: 1,
  },
  [DESKTOP]: {
    slidesPerView: 1.6,
    slidesPerGroup: 1,
  },
  [LARGE_DESKTOP]: {
    slidesPerView: 1.6,
    slidesPerGroup: 1,
  },
  [EXTRA_LARGE_DESKTOP]: {
    slidesPerView: 1.6,
    slidesPerGroup: 1,
  },
})
