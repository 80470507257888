import React, { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import styled from 'styled-components'
import { Button, Dialog, TypographyText } from '@nordic-web/ui-components'
import { useIsClientSide } from '@nordic-web/utils/hooks/use-is-client-side'
import { mtvTiers } from '@nordic-web/utils/tiers/mtv-tiers'
import { tv4Tiers } from '@nordic-web/utils/tiers/tv4-tiers'
import { CmsLink } from '@/components/tier-override-select/cms-link'
import { BRAND, Brand } from '@/config/brand'
import { useGlobalEventListener } from '@/hooks/use-global-events'
import { useLocalStorage } from '@/hooks/use-local-storage'

const tiers = BRAND === Brand.TV4 ? tv4Tiers : mtvTiers

export const isValidTierValue = (tierId: string) => !!tiers.find((tier) => tier.id === tierId)

const phraseToTriggerOpen = 'tierselect'

const PreviewBanner = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(3),
  position: 'fixed',
  right: 20,
  bottom: 20,
  background: theme.color.base.primary,
  zIndex: 10,
  padding: theme.space(3),
  cursor: 'pointer',
}))

export const TierOverrideSelect = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isBannerShowing, setIsBannerShowing] = useState(true)
  const [input, setInput] = useState('')
  const apolloClient = useApolloClient()
  const isClientSide = useIsClientSide()
  const [currentTier, setCurrentTier] = useLocalStorage<string | undefined>('tierOverride', undefined)

  useGlobalEventListener('keydown', (event) => {
    setInput((prevInput) => prevInput + (event as KeyboardEvent).key)
  })

  useEffect(() => {
    if (input.endsWith(phraseToTriggerOpen)) {
      setIsOpen(true)
      setInput('')
    }
  }, [input])

  const selectTier = (tier?: string) => {
    setCurrentTier(tier)
    apolloClient.resetStore()
    setIsBannerShowing(true)
  }

  useEffect(() => {
    // To avoid having "undefined" or a non tier value set in the localstorage
    if (currentTier && !isValidTierValue(currentTier)) {
      selectTier('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTier])

  if (!isClientSide) {
    return null
  }

  return (
    <>
      {currentTier && isClientSide && isBannerShowing && (
        <PreviewBanner>
          You are previewing <strong>{tiers.find((tier) => tier.id === currentTier)?.name}</strong>
          <Button onClick={() => setIsOpen(true)} nwVariant="outline" nwSize="small">
            Edit
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation()
              setIsBannerShowing(false)
            }}
            nwVariant="outline"
            nwSize="small"
          >
            Close banner
          </Button>
        </PreviewBanner>
      )}
      <Dialog nwInitOpen={isOpen} onClose={() => setIsOpen(false)} nwVariant="drawer">
        <Dialog.Top />
        <Dialog.DrawerBody>
          <TypographyText nwTextAlign="center" nwVariant="title3">
            Select a tier to preview
          </TypographyText>
          {tiers.map((tier) => {
            return (
              <Button
                nwVariant={tier.id === currentTier ? 'primary' : 'secondary'}
                key={tier.id}
                onClick={() => selectTier(tier.id)}
              >
                {tier.name}
              </Button>
            )
          })}
          {currentTier && (
            <Button onClick={() => selectTier('')} nwVariant="ghost">
              Remove preview
            </Button>
          )}

          {isOpen && <CmsLink />}
        </Dialog.DrawerBody>
      </Dialog>
    </>
  )
}
