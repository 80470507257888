import type { MouseEvent } from 'react'
import React from 'react'
import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import { ButtonCollapse, ButtonGroup } from '@nordic-web/ui-components'
import { tablet } from '@nordic-web/ui-styles'
import type { TrailerVideoController } from '@/components/background-trailer'
import { useTrailersMutedState } from '@/components/trailer/use-trailers-muted-state'

const ControlWrapper = styled.div({
  display: 'none',
  marginLeft: 'auto',
  [tablet]: {
    display: 'flex',
  },
})

type VideoProps = {
  trailerVideoController: TrailerVideoController | null
}

export const BackgroundTrailerControls = ({ trailerVideoController }: VideoProps) => {
  const { setIsMuted, isMuted } = useTrailersMutedState()
  const handleMute = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsMuted(!isMuted)
  }
  const handleStartOver = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    trailerVideoController?.restart()
  }

  const t = useTranslations()

  return (
    <ControlWrapper className="trailer-controls">
      <ButtonGroup>
        <ButtonCollapse nwIcon="start-over" onClick={handleStartOver} nwVariant="secondary">
          {t('trailer__replay')}
        </ButtonCollapse>
        <ButtonCollapse
          data-test="mute-button"
          nwIcon={isMuted ? 'volume-mute' : 'volume'}
          onClick={handleMute}
          nwVariant="secondary"
        >
          {isMuted ? t('trailer__unmute_button') : t('trailer__mute_button')}
        </ButtonCollapse>
      </ButtonGroup>
    </ControlWrapper>
  )
}
