import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { useQueryClient } from '@tanstack/react-query'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { useHasLoginStateChanged } from '@/features/auth/hooks/use-has-login-state-changed'
import { subscriptionQueryKey } from '@/features/checkout/hooks/use-subscription'
import { packageKey, smallPackageKey } from '@/features/packages/hooks/use-packages'
import { useRenoNotification } from '@/features/reno/hooks/use-reno-notification'

export const OnSubscriptionChange = () => {
  const apolloClient = useApolloClient()
  const queryClient = useQueryClient()
  const { refetchAccessToken } = useAuthState()

  useRenoNotification('SUBSCRIPTION_CHANGED', async () => {
    await refetchAccessToken()
    apolloClient.resetStore()
    queryClient.invalidateQueries({ queryKey: [subscriptionQueryKey] })
    queryClient.invalidateQueries({ queryKey: [subscriptionQueryKey, ...packageKey] })
    queryClient.invalidateQueries({ queryKey: packageKey })
    queryClient.invalidateQueries({ queryKey: smallPackageKey })
  })

  // Reset on login state change
  const hasLoginStateChanged = useHasLoginStateChanged()
  useEffect(() => {
    if (hasLoginStateChanged) {
      console.log('Resetting the apollo store because of logging in')
      apolloClient.resetStore()
      queryClient.clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoginStateChanged])

  return null
}
