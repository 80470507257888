import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import type { TrailerFieldsFragment } from '@nordic-web/gql'
import { useIsClientSide } from '@nordic-web/utils/hooks/use-is-client-side'
import { BackgroundImage } from '@/components/background-image/background-image'
import { PAUSE_PROMO, PAUSE_TRAILER_EVENT } from '@/components/trailer/events'
import { useMuteTrailer } from '@/components/trailer/use-mute-trailer'
import { useTrailersMutedState } from '@/components/trailer/use-trailers-muted-state'
import { useIsAboveTablet } from '@/hooks/use-breakpoint'
import { useIsBelowTablet } from '@/hooks/use-breakpoint'
import { useGlobalEventListener } from '@/hooks/use-global-events'
import { shouldDevicePlayVideo } from './should-device-play-video'
import { ImageContainer, Video, VideoFade, VideoWrap } from './style'

const VIDEO_TIMEOUT_MS = 10000
const VIDEO_PLAY_DELAY = 2000
const VISIBILITY_THRESHOLD = 0.5

type BackgroundTrailerProps = {
  desktopImage?: string
  mobileImage?: string
  trailers?: TrailerFieldsFragment | null
  color?: string
  imagePriority?: boolean
}

export type TrailerVideoController = {
  restart: () => void
}

export const BackgroundTrailer = forwardRef<TrailerVideoController, BackgroundTrailerProps>(function VideoComponent(
  { mobileImage, desktopImage, trailers, color, imagePriority },
  ref
) {
  const isClientSide = useIsClientSide()
  const isAboveTablet = useIsAboveTablet()
  const [hasVideoTimedout, setHasVideoTimedout] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)
  const [hasBeenPausedByEvent, setHasBeenPausedByEvent] = useState(false)
  const [hasVideoEnded, setVideoEnded] = useState(false)
  const [isBrowserTabIsVisible, setIsBrowserTabIsVisible] = useState(true)
  const { isMuted } = useTrailersMutedState()
  const timeoutTimer = useRef<NodeJS.Timeout>()
  const loadDelayTimer = useRef<NodeJS.Timeout>()
  const isMobile = useIsBelowTablet()

  const [inViewRef, isInView] = useInView({ threshold: VISIBILITY_THRESHOLD })

  const isVisible = isInView && isBrowserTabIsVisible

  const videoRef = useRef<HTMLVideoElement | null>(null)

  // The trailer is ready to be played again if it has been scrolled out of view and in again
  useEffect(() => {
    if (hasBeenPausedByEvent && !isVisible) {
      setHasBeenPausedByEvent(false)
    }
  }, [hasBeenPausedByEvent, isVisible])

  useImperativeHandle(ref, () => ({
    restart: () => {
      if (!videoRef.current) return
      videoRef.current.currentTime = 0
      playVideo()
    },
  }))

  const playVideo = useCallback(() => {
    try {
      videoRef.current?.play?.()
      setHasBeenPausedByEvent(false)
    } catch (error) {
      console.error('Unable to play trailer: ', error)
    }
  }, [])

  const pauseVideo = useCallback(() => {
    videoRef.current?.pause()
    clearTimeout(loadDelayTimer.current)
  }, [])

  // Pause the promo trailer when a Card-trailer is played
  useGlobalEventListener(PAUSE_TRAILER_EVENT, (event) => {
    if ((event as CustomEvent).detail === PAUSE_PROMO) {
      pauseVideo()
      setHasBeenPausedByEvent(true)
    }
  })

  useGlobalEventListener('visibilitychange', () => setIsBrowserTabIsVisible(document.visibilityState === 'visible'))

  useMuteTrailer(videoRef.current, isMuted, isVideoLoaded)

  // Prevent videos from autoplaying if loading takes long time.
  useEffect(() => {
    timeoutTimer.current = setTimeout(() => {
      setHasVideoTimedout(true)
    }, VIDEO_TIMEOUT_MS)

    return () => {
      clearTimeout(timeoutTimer.current)
    }
  }, [])

  // Handle play/pause when scrolled into/out of view. Make sure to not override other playing trailers.
  useEffect(() => {
    if (!videoRef.current) return

    if (isVisible && !hasBeenPausedByEvent) {
      loadDelayTimer.current = setTimeout(() => {
        if (videoRef.current?.paused && videoRef.current.muted && !hasVideoEnded) {
          playVideo()
        }
      }, VIDEO_PLAY_DELAY)

      return () => {
        clearTimeout(loadDelayTimer.current)
      }
    } else if (isPlaying) {
      pauseVideo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, hasVideoEnded, isPlaying])

  const shouldHideImage = isVideoLoaded && !hasVideoEnded && isPlaying

  const shouldRenderVideo =
    isClientSide && isAboveTablet && shouldDevicePlayVideo() && !hasVideoTimedout && (trailers?.mp4 || trailers?.webm)

  return (
    <>
      <VideoFade loaded={isVideoLoaded && !hasVideoEnded ? 1 : 0} />
      <VideoWrap ref={inViewRef} visible={shouldHideImage}>
        {shouldRenderVideo && (
          <Video
            ref={videoRef}
            onEnded={() => {
              setVideoEnded(true)
              setIsPlaying(false)
            }}
            onLoadedData={() => {
              setIsVideoLoaded(true)
              clearTimeout(timeoutTimer.current)
            }}
            onPause={() => {
              setIsPlaying(false)
            }}
            onPlay={() => {
              setVideoEnded(false)
              setIsPlaying(true)
            }}
          >
            {isVisible && trailers.mp4 && <source src={trailers.mp4} type="video/mp4" />}
            {isVisible && trailers.webm && <source src={trailers.webm} type="video/webm" />}
          </Video>
        )}
      </VideoWrap>

      <ImageContainer visible={!shouldHideImage} backgroundColorHex={color}>
        {!isMobile && desktopImage && <BackgroundImage src={desktopImage} imagePriority={imagePriority} />}
        {isMobile && mobileImage && <BackgroundImage src={mobileImage} imagePriority={imagePriority} />}
      </ImageContainer>
    </>
  )
})
