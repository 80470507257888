export const isInternalPath = (url?: string) => {
  if (!url) return false

  // //google.com is a valid href, and would lead you to google. But its not parsed by URL()
  if (url.startsWith('//')) {
    return false
  }

  try {
    new URL(url)
    return false
  } catch (error) {
    return true
  }
}
