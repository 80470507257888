import { RENO_EVENT } from '@/features/reno/reno-notification-listener'
import type { RenoNotification } from '@/features/reno/types'
import { useGlobalEventListener } from '@/hooks/use-global-events'

export const useRenoNotification = (
  type: RenoNotification['type'],
  callback: (notification?: RenoNotification) => void
) => {
  useGlobalEventListener(RENO_EVENT, (event) => {
    const notification = (event as CustomEvent).detail as RenoNotification
    if (notification.type === type) {
      callback(notification)
    }
  })
}
