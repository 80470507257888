import { useEffect, useMemo, useState } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import {
  type CardDetails,
  type CheckoutPackage,
  type KlarnaDetails,
  type VoucherResponse,
  type VoucherSuccess,
} from '@nordic-web/rest-codegen/generated/subscription'
import { Button, CircleSpinner, Stack } from '@nordic-web/ui-components'
import { tablet } from '@nordic-web/ui-styles'
import { PageContainer } from '@/components/scaffolding/page-container'
import { SubscriptionAlerts } from '@/components/subscription-alerts'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { AuthModalPaths } from '@/features/auth/types'
import { AcceptTerms } from '@/features/checkout/components/accept-terms'
import { BindingToggle } from '@/features/checkout/components/binding-toggle'
import { CheckoutHeader } from '@/features/checkout/components/checkout-header'
import { UpgradeWithDiscountInfo } from '@/features/checkout/components/upgrade-with-discount-info'
import { useCheckoutState } from '@/features/checkout/context/checkout-state-context'
import { useConversionCheck } from '@/features/checkout/hooks/use-conversion-check'
import { useResetVoucher } from '@/features/checkout/hooks/use-reset-voucher'
import { useSubscription } from '@/features/checkout/hooks/use-subscription'
import { GoBackButtonArrow } from '@/features/packages/components/go-back-button'
import { PackageCardMedium } from '@/features/packages/components/package-card-md'
import { VoucherButton } from '@/features/voucher/components/voucher-button'
import { VoucherError } from '@/features/voucher/components/voucher-error'
import { VoucherInfo } from '@/features/voucher/components/voucher-info'
import { VoucherModal } from '@/features/voucher/components/voucher-modal'
import { paths } from '@/helpers/paths'
import { useCancelFlowTracking } from '@/tracking/cancel-flow-tracking'
import { stringFromQueryParam } from '@/utils/query-string'

const BottomContainer = styled(Stack)({
  width: '100%',
  maxWidth: '340px',
})

const ButtonWithTopMargin = styled(Button)(({ theme }) => ({
  [tablet]: {
    marginTop: theme.space(2),
  },
}))

const VoucherButtonSpan = styled.span(({ theme }) => ({
  marginLeft: theme.space(2),
  color: theme.color.text.primary,
}))

type UpgradePackageProps = {
  packageData: CheckoutPackage
  isLoading: boolean
  paymentMethod?: CardDetails | KlarnaDetails
}

const isVoucherValid = (response?: VoucherResponse): response is VoucherSuccess => !!response?.valid
const isVoucherValidForPackage = (voucher: VoucherSuccess, packageId: string) =>
  !voucher.products?.some((product) => product.productGroupId.toString() !== packageId)

export const voucherIsApplied = (voucher: VoucherResponse | undefined, packageId: string): voucher is VoucherSuccess =>
  isVoucherValid(voucher) && isVoucherValidForPackage(voucher, packageId)
const voucherIsInvalidForPackage = (voucher: VoucherResponse | undefined, packageId: string) =>
  isVoucherValid(voucher) && !isVoucherValidForPackage(voucher, packageId)

export const UpgradePackage = ({ packageData, isLoading }: UpgradePackageProps) => {
  const t = useTranslations()
  const router = useRouter()
  const returnUrl = stringFromQueryParam(router.query?.return)
  const { dispatch } = useAuthModal()
  const [isVoucherModalOpen, setIsVoucherModalOpen] = useState(false)
  const { hasValidAccessToken, isLoggedIn } = useAuthState()
  const {
    subscriptionState: { data: subscriptionState },
    voucher: { data: voucherData, refetch: getVoucher, isFetching: isVoucherLoading },
  } = useSubscription()
  const { isConversionDisabled } = useConversionCheck('UPGRADE')
  const { hasAcceptedTerms, setHasAcceptedTerms, isCheckingOutWithBinding, productDetails } = useCheckoutState()
  const tracking = useCancelFlowTracking()
  const resetVoucher = useResetVoucher()

  const isBindingAvailable = useMemo(() => {
    return !!packageData.defaultBindingProduct && !voucherIsApplied(voucherData, packageData.vimondId)
  }, [packageData, voucherData])

  const hasFreeTier = subscriptionState?.active?.price === 0
  const isCurrentlyBoundToSubscription = subscriptionState?.active?.isCurrentlyBoundToThisProduct
  // If user currently has binding, they can not upgrade to package that does not have binding available
  const isUpgradeAvailable = !isConversionDisabled && !(isCurrentlyBoundToSubscription && !isBindingAvailable)
  const isEligibleForVoucher = isUpgradeAvailable && hasFreeTier && !(isCheckingOutWithBinding && isBindingAvailable)
  const voucherFromQuery = stringFromQueryParam(router.query?.voucherCode)

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch({ type: 'show-auth-modal', path: AuthModalPaths.SIGNUP })
    }
  }, [isLoggedIn, dispatch])

  useEffect(() => {
    if (voucherFromQuery && !voucherData && hasValidAccessToken) {
      getVoucher()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasValidAccessToken])

  useEffect(() => {
    // When the modal is opened or closed we remove any old, invalid vouchers that could be present
    if (voucherData && !voucherData.valid) {
      resetVoucher()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVoucherModalOpen])

  useEffect(() => {
    // If voucherData belongs to different package we remove it. Happens if voucher is first valid, and user navigates to different package
    if (voucherIsInvalidForPackage(voucherData, packageData.vimondId)) {
      resetVoucher()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherData])

  const upgradeWithBindingDisclaimer = useMemo(() => {
    if (isCurrentlyBoundToSubscription && isCheckingOutWithBinding) {
      return t.rich('subscription__checkout__upgrade__binding_disclaimer', {
        discountedPrice: productDetails?.initPrice,
        price: productDetails?.price,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionState?.active, isCheckingOutWithBinding, productDetails])

  const handleClickTermsCheckbox = () => {
    setHasAcceptedTerms(!hasAcceptedTerms)
  }

  const discountedPrice =
    isBindingAvailable && isCheckingOutWithBinding
      ? packageData.discountedBindingUpgradePrice
      : packageData.discountedUpgradePrice

  const handleCheckoutClick = () => {
    tracking?.confirmUpgrade(packageData.vimondId, packageData.tierId)
    router.push(
      paths.checkoutPayment.urlString({
        packageId: packageData.vimondId,
        return: stringFromQueryParam(router.query?.return),
        voucherCode: !voucherData?.valid ? undefined : voucherData?.code,
      })
    )
  }

  tracking?.trackPage('upgrade')

  return (
    <PageContainer>
      <Stack nwGap={8} nwAlignItems="center" data-testid="checkout-upgrade-page">
        <GoBackButtonArrow
          href={
            router.query.from === 'campaign'
              ? `${router.query.return}`
              : paths.changePackage.urlString({ return: returnUrl })
          }
        />
        <CheckoutHeader>{t('subscription__checkout__upgrade__title')}</CheckoutHeader>
        <SubscriptionAlerts conversion={'UPGRADE'} />
        {isLoading || isVoucherLoading ? (
          <CircleSpinner />
        ) : (
          <>
            {!isConversionDisabled && isBindingAvailable && <BindingToggle disabled={isCurrentlyBoundToSubscription} />}
            <PackageCardMedium packageData={packageData} showReadMoreButton />
            {isUpgradeAvailable && discountedPrice && (
              <UpgradeWithDiscountInfo
                discountedPrice={discountedPrice}
                bindingDisclaimer={upgradeWithBindingDisclaimer}
              />
            )}
            {!isLoggedIn ? (
              <Button
                nwLayout="full-width"
                onClick={() => dispatch({ type: 'show-auth-modal', path: AuthModalPaths.SIGNUP })}
              >
                {t('subscription__checkout__upgrade__sign_up_prompt')}
              </Button>
            ) : (
              <BottomContainer nwGap={4}>
                {isEligibleForVoucher && !voucherData?.valid && !voucherFromQuery && (
                  <VoucherButton nwVariant="secondaryDark" onClick={() => setIsVoucherModalOpen(true)}>
                    {t('subscription__voucher_modal_title')}
                    <VoucherButtonSpan>{t('general__click_here')}</VoucherButtonSpan>
                  </VoucherButton>
                )}
                {isEligibleForVoucher && voucherIsApplied(voucherData, packageData.vimondId) && voucherData.code && (
                  <VoucherInfo voucherData={voucherData} packageData={packageData} resetVoucher={resetVoucher} />
                )}
                {voucherData && !voucherData.valid && !isVoucherModalOpen && (
                  <VoucherError resetVoucher={resetVoucher} />
                )}
                {isUpgradeAvailable && (
                  <AcceptTerms checked={hasAcceptedTerms} handleClick={handleClickTermsCheckbox} />
                )}
                <ButtonWithTopMargin
                  data-testid="checkout-upgrade-button"
                  disabled={!hasAcceptedTerms || !isUpgradeAvailable}
                  onClick={() => handleCheckoutClick()}
                >
                  {t('subscription__checkout__upgrade__continue_to_payment')}
                </ButtonWithTopMargin>
              </BottomContainer>
            )}
          </>
        )}
        {hasFreeTier && (
          <VoucherModal isOpen={isVoucherModalOpen} setIsOpen={setIsVoucherModalOpen} resetVoucher={resetVoucher} />
        )}
      </Stack>
    </PageContainer>
  )
}
