import { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'
import { useForm } from 'react-hook-form'
import {
  Button,
  ButtonGroup,
  Dialog,
  Form,
  FormInputCheckbox,
  Icon,
  Stack,
  TypographyText,
} from '@nordic-web/ui-components'
import { useIap } from '@/features/packages/hooks/use-iap'
import { paths } from '@/helpers/paths'

type CancelIapModalProps = {
  isOpen: boolean
  onClose: () => void
}

type CancelIapFormFields = { hasAcceptedTermsField: boolean }

export const CancelIapModal = ({ isOpen, onClose }: CancelIapModalProps) => {
  const t = useTranslations()
  const {
    deleteIapSubscription: { mutateAsync: deleteIapSubscriptionForUser },
  } = useIap()
  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<CancelIapFormFields>()
  const [hasAcceptedTerms, setTermsAccepted] = useState(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const handleClose = () => {
    setTermsAccepted(false)
    reset({ hasAcceptedTermsField: false })
    onClose()
  }

  const onSubmit = async (_data: CancelIapFormFields) => {
    const isOk = await deleteIapSubscriptionForUser()
    if (isOk) {
      setIsSuccess(true)
    } else {
      setError('hasAcceptedTermsField', { message: t('subscription__iap__modal_error') })
    }
  }

  useEffect(() => {
    clearErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog nwVariant="drawer" nwInitOpen={isOpen} onClose={handleClose}>
      <Dialog.Top />
      <Dialog.DrawerBody>
        {!isSuccess ? (
          <>
            <TypographyText nwVariant="title3" nwTextAlign="center">
              {t('subscription__iap__modal_title')}
            </TypographyText>
            <TypographyText nwVariant="body3" nwColor="secondary" nwTextAlign="center">
              {t('subscription__iap__modal_desc')}
            </TypographyText>
            <TypographyText nwVariant="body3" nwColor="secondary" nwTextAlign="center">
              {t('subscription__iap__modal_note')}
            </TypographyText>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Stack>
                <FormInputCheckbox
                  nwLabel={
                    <TypographyText nwVariant="body3" nwColor="secondary">
                      {t('subscription__iap__modal_consent')}
                    </TypographyText>
                  }
                  validators={register('hasAcceptedTermsField')}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  nwErrorMessage={errors.hasAcceptedTermsField?.message}
                />
              </Stack>
              <ButtonGroup nwFullWidth nwAxis="y" nwPlacement="center">
                <Button type="submit" disabled={!hasAcceptedTerms}>
                  {t('subscription__iap__deactivate_subscription')}
                </Button>
                <Button nwVariant="ghost" onClick={handleClose}>
                  {t('general__back')}
                </Button>
              </ButtonGroup>
            </Form>
          </>
        ) : (
          <>
            <Stack nwGap={4} nwAlignItems="center">
              <Icon nwVariant="check-filled" aria-hidden />
            </Stack>
            <TypographyText nwVariant="title3" nwTextAlign="center">
              {t('subscription__iap__success_title')}
            </TypographyText>
            <TypographyText nwVariant="body3" nwColor="secondary" nwTextAlign="center">
              {t('subscription__iap__success_desc')}
            </TypographyText>
            <Button type="submit" as="a" href={paths.packages.urlString()}>
              {t('general__continue')}
            </Button>
          </>
        )}
      </Dialog.DrawerBody>
    </Dialog>
  )
}
