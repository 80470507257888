import type { ReactNode } from 'react'
import { createContext, useContext, useMemo, useSyncExternalStore } from 'react'

type PlayerTime = {
  setPlayerTime: (time: number) => void
  setPlayerTimestamp: (timestamp: number) => void
  getSnapshot: () => {
    playerTimestamp: number
    playerTime: number
  }
  subscribe: (listener: () => void) => () => void
}

const PlayerTimeContext = createContext<PlayerTime | undefined>(undefined)

type PlayerTimeContextProviderProps = {
  children: ReactNode
}

export const PlayerTimeContextProvider = ({ children }: PlayerTimeContextProviderProps) => {
  const store = useMemo(() => {
    let state = {
      playerTime: -1,
      playerTimestamp: -1,
    }
    let listeners: (() => void)[] = []

    function emitChange() {
      for (const listener of listeners) {
        listener()
      }
    }

    return {
      setPlayerTime(value: number) {
        if (state.playerTime !== value) {
          state = { ...state, playerTime: value }
          emitChange()
        }
      },
      setPlayerTimestamp(value: number) {
        if (state.playerTimestamp !== value) {
          state = { ...state, playerTimestamp: value }
          emitChange()
        }
      },
      getSnapshot() {
        return state
      },
      subscribe(listener: () => void) {
        listeners = [...listeners, listener]
        return () => {
          listeners = listeners.filter((l) => l !== listener)
        }
      },
    }
  }, [])

  return <PlayerTimeContext.Provider value={store}>{children}</PlayerTimeContext.Provider>
}

export const usePlayerTimeContext = () => {
  const context = useContext(PlayerTimeContext)

  if (typeof context === 'undefined') {
    throw new Error('usePlayerTimeContext must be used within a PlayerTimeContext')
  }

  return context
}

export const usePlayerTime = () => {
  const context = usePlayerTimeContext()
  return useSyncExternalStore(context.subscribe, context.getSnapshot)
}
