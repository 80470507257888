import { type ComponentPropsWithoutRef, type PropsWithChildren, forwardRef } from 'react'
import styled from 'styled-components'
import { Icon, type IconColors, type IconVariant } from '../../icon/icon/icon'
import { CircleSpinner } from '../../spinners/circle-spinner/circle-spinner'
import type { TypographyTextProps } from '../../typography/typography-text/typography-text'
import { TypographyText } from '../../typography/typography-text/typography-text'

type TypographyTextVariant = TypographyTextProps['nwVariant']

export const buttonVariants = [
  'primary',
  'secondary',
  'secondaryTransparent',
  'secondaryTransparent2',
  'secondaryDark',
  'ghost',
  'outline',
] as const

type NwSize = 'small' | 'regular'
type NwLayout = 'default' | 'full-width'
type NwBorderType = 'regular' | 'small' | 'none'

type ButtonVariant = (typeof buttonVariants)[number]

export type ButtonElements = 'button' | 'a'

export type ButtonProps = PropsWithChildren<
  {
    nwVariant?: ButtonVariant
    nwLoading?: boolean
    nwLayout?: NwLayout
    nwBorderType?: NwBorderType
    nwSize?: NwSize
    nwIconFirst?: IconVariant
    nwIconLast?: IconVariant
    nwIconFirstColor?: IconColors
    nwTextVariant?: TypographyTextVariant
    as?: ButtonElements
    target?: string
    href?: string
    className?: string
  } & ComponentPropsWithoutRef<'button'>
>

export const Button = forwardRef(function Button(
  {
    children,
    as = 'button',
    nwVariant = 'primary',
    nwLayout,
    nwLoading,
    nwIconFirst,
    nwIconLast,
    nwIconFirstColor,
    nwTextVariant = 'CTA',
    className,
    nwSize = 'regular',
    ...forwardProps
  }: ButtonProps,
  ref?: React.Ref<HTMLButtonElement>
) {
  const iconSize = nwSize === 'regular' ? 'small' : 'tiny'
  return (
    <StyledButton
      className={className}
      as={as}
      nwVariant={nwTextVariant}
      nwButtonVariant={nwVariant}
      nwLayout={nwLayout}
      nwSize={nwSize}
      {...forwardProps}
      disabled={nwLoading || forwardProps.disabled}
      hasChildren={!!children}
      ref={ref}
    >
      <Inner>
        {!nwLoading && nwIconFirst && <Icon nwVariant={nwIconFirst} nwSize={iconSize} nwColor={nwIconFirstColor} />}
        {nwLoading && <CircleSpinner nwVariant="secondary" nwSize="button" />}
        {/** Without this span, the site crashes when using Google Translate */}
        {children && <span className="nw-button-text">{children}</span>}
        {nwIconLast && <Icon nwVariant={nwIconLast} nwSize={iconSize} />}
      </Inner>
    </StyledButton>
  )
})

type StyledButtonProps = {
  nwSize: NwSize
  nwLayout?: NwLayout
  nwBorderType?: NwBorderType
  nwButtonVariant: ButtonVariant
  disabled?: boolean
  hasChildren: boolean
}

const StyledButton = styled(TypographyText)<StyledButtonProps>(({
  theme,
  nwButtonVariant,
  nwLayout,
  nwSize,
  nwBorderType = 'regular',
  hasChildren,
}) => {
  const borderRadiuses = {
    regular: theme.radii.border_radius,
    small: theme.radii.radiiX(2),
    none: 0,
  }

  return {
    appearance: 'none',
    border: 'none',
    borderRadius: borderRadiuses[nwBorderType],
    height: 40,
    paddingInline: hasChildren ? theme.space(4) : theme.space(3),
    fontFamily: theme.fonts.body,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    ...(nwSize === 'small' && {
      height: 24,
      ...theme.typography.smallText,
      paddingInline: theme.space(2),
    }),
    ...(nwButtonVariant === 'outline' && {
      border: `1px solid currentColor`,
    }),
    ...(nwLayout === 'full-width' && {
      width: '100%',
    }),
    color: theme.color.button[nwButtonVariant].text,
    backgroundColor: theme.color.button[nwButtonVariant].default,
    '&:hover': {
      backgroundColor: theme.color.button[nwButtonVariant].hover,
    },
    '&:active': {
      backgroundColor: theme.color.button[nwButtonVariant].pressed,
    },
    '&[disabled]': {
      backgroundColor: theme.color.button[nwButtonVariant].disabled,
      cursor: 'not-allowed',
      color: theme.color.button.textDisabled,
    },
  }
})

const Inner = styled.div(({ theme }) => ({
  flex: 1,
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.space(2),
}))
