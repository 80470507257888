import Link from 'next/link'
import styled from 'styled-components'
import type { ChannelFieldsFragment } from '@nordic-web/gql'
import { ResponsiveImage, Stack, cardHoverEffect } from '@nordic-web/ui-components'
import { tablet } from '@nordic-web/ui-styles'
import { isPast } from '@nordic-web/utils/date'
import { useReRenderWithInterval } from '@nordic-web/utils/hooks/use-re-render-interval'
import { useUpdateEpg } from '@/components/epg-channel-card/use-update-epg'
import { EpgList } from '@/components/epg-list'
import { MissingEpgMessage } from '@/features/channel-epg-panel/components/missing-epg-message'
import { paths } from '@/helpers/paths'
import { AssetTracking } from '@/tracking/asset-tracking'

type Variant = 'light' | 'dark'

type ContainerProps = {
  variant: Variant
  disabled: boolean
}

const Container = styled(Link)<ContainerProps>(({ theme, variant, disabled }) => ({
  height: '100%',
  borderRadius: theme.radii.border_radius,
  background: variant === 'light' ? theme.color.surface.white5 : theme.color.surface.black40,
  display: 'flex',
  padding: `${theme.space(4)} ${theme.space(4)}`,
  '&:hover': {
    background: variant === 'light' ? theme.color.surface.white20 : theme.color.surface.black50,
    textDecoration: 'none',
  },
  ...(disabled && {
    opacity: 0.5,
    pointerEvents: 'none',
  }),
  ...cardHoverEffect,
  [tablet]: {
    padding: `${theme.space(6)} ${theme.space(4)}`,
    minHeight: 150,
  },
}))

const FullWidthStack = styled(Stack)({ width: '100%' })
const ContentWrapper = styled(Stack)({ flex: 1 })

type EpgChannelCardProps = {
  channel: ChannelFieldsFragment
  variant?: Variant
  replace?: boolean
  className?: string
}

export const EpgChannelCard = ({ channel, variant = 'light', replace = false, className }: EpgChannelCardProps) => {
  useUpdateEpg(channel.id)
  useReRenderWithInterval()

  const epg = channel.epg?.filter((item) => !isPast(item.end))
  const hasEpg = !!epg?.length
  const logo = channel.images.logo?.sourceEncoded

  return (
    <AssetTracking content_media_id={channel.id}>
      {({ trackOnAssetClick }) => (
        <Container
          replace={replace}
          href={paths.video.urlString({ assetId: channel.id, slug: channel.slug })}
          variant={variant}
          disabled={!channel.access.hasAccess}
          className={className}
          onClick={trackOnAssetClick}
        >
          <FullWidthStack nwDirection="row" nwGap={6}>
            {logo && (
              <Stack nwJustifyContent="center">
                <ResponsiveImage width={45} alt={channel.title} src={logo} />
              </Stack>
            )}
            <ContentWrapper nwJustifyContent="center">
              {hasEpg ? <EpgList epg={epg.slice(0, 3)} /> : <MissingEpgMessage channel={channel} />}
            </ContentWrapper>
          </FullWidthStack>
        </Container>
      )}
    </AssetTracking>
  )
}
