import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import useWebSocket from 'react-use-websocket'
import type { Options } from 'react-use-websocket'
import * as RenoService from '@nordic-web/rest-codegen/generated/reno'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import type { RenoNotification } from '@/features/reno/types'
import { nextConfig } from '@/helpers/env'

/** The time between two long polling requests */
const RENO_POLLING_INTERVAL = 20_000

RenoService.OpenAPI.BASE = nextConfig.string('RENO_API')
/** The timestamp of the last seen notifications, it's used to only fetch newer notifications */
type LastSeen = number

/**
 * useReno
 * Rapid Event Notification (RENO) is a service that allows you to subscribe to notifications from the backend.
 * - connect to the reno web socket
 *   - send a ping every 60 seconds to keep the connection alive
 * - fallback to long polling if the socket connection fails
 */
export const useReno = (lastSeen: LastSeen) => {
  const { accessToken, hasValidAccessToken } = useAuthState()
  const [isSocketConnected, setIsSocketConnected] = useState(false)
  const [socketUrl, setSocketUrl] = useState<string | null>(null)

  RenoService.OpenAPI.TOKEN = accessToken

  const { data: renoData } = useQuery({
    queryKey: ['reno'],
    enabled: hasValidAccessToken && !isSocketConnected,
    staleTime: Infinity,
    refetchOnWindowFocus: 'always',
    refetchInterval: RENO_POLLING_INTERVAL,
    queryFn: () =>
      RenoService.getNotifications({
        since: lastSeen,
      }),
  })

  const shouldConnect = hasValidAccessToken && !!renoData?.realtimeStream?.url

  const options: Options = {
    onOpen: () => {
      setIsSocketConnected(true)
    },
    onClose: () => {
      setIsSocketConnected(false)
      setSocketUrl(null)
    },
    retryOnError: true,
    reconnectAttempts: 15,
    heartbeat: {
      interval: 60_000 * 5,
      timeout: 60_000 * 10,
    },
    reconnectInterval: (lastAttemptNumber: number) => Math.min(1000 * 2 ** lastAttemptNumber),
  }

  const socket = useWebSocket<RenoNotification>(socketUrl, options, shouldConnect)

  useEffect(() => {
    if (!socketUrl && accessToken && renoData?.realtimeStream?.url) {
      setSocketUrl(`${renoData.realtimeStream.url}?token=${accessToken}`)
    }
  }, [accessToken, renoData?.realtimeStream?.url, socketUrl])

  return { socketNotification: socket.lastJsonMessage, httpNotifications: renoData?.notifications }
}
