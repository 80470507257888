import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useLocalStorage } from '@/hooks/use-local-storage'
import { dataLayerPush } from '@/tracking/tracking'

export const DEVICE_ID_LOCAL_STORAGE_KEY = 'device.id'

export const DeviceIdTracking = () => {
  const [deviceId, setDeviceId] = useLocalStorage(DEVICE_ID_LOCAL_STORAGE_KEY, uuidv4())

  useEffect(() => {
    try {
      if (!deviceId) return
      setDeviceId(deviceId)
      dataLayerPush({ event: 'set_device_id', device_id: deviceId })
    } catch (err) {
      console.error(`DeviceIdTracking error ${err}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId])

  return null
}
