import React from 'react'
import styled from 'styled-components'
import { TypographyText } from '@nordic-web/ui-components'

type PackageLabelProps = {
  packageName: string
  isPackageCard?: boolean
}

const PackageCardLabelText = styled(TypographyText)({
  height: 28, // fixed height to create horizontal rhythm within package cards
})

export const PackageLabel = ({ packageName, isPackageCard = false }: PackageLabelProps) => {
  return isPackageCard ? (
    <PackageCardLabelText nwVariant="packageCardBody" nwTextAlign="center">
      {packageName.toUpperCase()}
    </PackageCardLabelText>
  ) : (
    <TypographyText nwVariant="packageCardTitle" nwTextAlign="center">
      {packageName.toUpperCase()}
    </TypographyText>
  )
}
