import React from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import styled from 'styled-components'
import { SiteLogo } from '@/components/site-logo/site-logo'
import { paths } from '@/helpers/paths'
import { aboveLargeDesktopHeader, aboveTabletHeader } from './style'

export const HeaderLogo = () => {
  const t = useTranslations()

  return (
    <Link href={paths.start.url()} passHref legacyBehavior aria-label={t('aria__start_page_link')}>
      <A data-test="menu-desktop-logo">
        <SiteLogo />
      </A>
    </Link>
  )
}

const A = styled.a({
  lineHeight: 0,
  height: 40,
  width: 40,
  [aboveTabletHeader]: {
    height: 48,
    width: 48,
  },
  [aboveLargeDesktopHeader]: {
    height: 56,
    width: 56,
  },
})
