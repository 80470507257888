import React from 'react'
import styled from 'styled-components'
import { useBriefSynopsisQuery } from '@nordic-web/gql'
import { Stack, TypographyText } from '@nordic-web/ui-components'
import {
  CardButtonContainer,
  CardContainer,
  CardHoverContent,
  CardImageContainer,
  CardLabelContainer,
} from '@nordic-web/ui-components'
import { isClientSide } from '@nordic-web/utils/misc/detect-side'
import { isUpsellMedia } from '@nordic-web/utils/misc/is-upsell-media'
import { isTypeInUnion } from '@nordic-web/utils/typescript'
import { CardVideoTrailer } from '@/components/cards/card-video-trailer'
import type { MediaPanelItem } from '@/components/cards/program-card'
import type { OnCardClick } from '@/components/cards/types'
import { FadeInImage } from '@/components/fade-in-image'
import { LabelFactory } from '@/components/label-factory'
import { StandardMetaPropertyList } from '@/components/meta-property-list/standard-meta-property-list'
import { usePrefetchCdpQuery } from '@/components/prefetch-links/cdp-prefetch-link'
import { MuteTrailersButton } from '@/components/trailer/mute-trailers-button'
import { TruncateLines } from '@/components/truncate-lines/truncate-lines'
import { FavoriteButton } from '@/features/favorite/favorite-button'
import { paths } from '@/helpers/paths'
import { useIsAboveTablet } from '@/hooks/use-breakpoint'
import { useHovered } from '@/hooks/use-hovered'
import { AssetTracking } from '@/tracking/asset-tracking'
import { detectTouch } from '@/utils/detect-touch'

const HoverCardTrailerContainer = styled.div({
  position: 'relative',
  width: '100%',
  aspectRatio: '16/9',
  flexShrink: 0,
})

const HoverCardMetaContainer = styled.div<{ backgroundColor: string | undefined }>(({ backgroundColor, theme }) => ({
  width: '100%',
  backgroundColor: backgroundColor || theme.color.base.background,
  bottom: 0,
  padding: theme.space(4),
  flexGrow: 1,
}))

type PosterCardProps = {
  program: MediaPanelItem
  onCardClick?: OnCardClick
  showOnlyUpsellLabel?: boolean
}

const PosterCardHoverContent = styled(CardHoverContent)({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  zIndex: 30,
})

export const PosterCard = ({ program, onCardClick, showOnlyUpsellLabel }: PosterCardProps) => {
  const id = program.id
  const slug = program.slug
  const isAboveTabletScreen = useIsAboveTablet()
  const shouldShowHoverContent = isClientSide && isAboveTabletScreen && !detectTouch()
  const prefetch = usePrefetchCdpQuery(id)
  const { data: extendedProgramData } = useBriefSynopsisQuery({
    variables: { id },
    ssr: false,
    context: { batch: true },
    skip: !shouldShowHoverContent,
  })
  const media = extendedProgramData?.media
  const extendedProgram = isTypeInUnion(media, 'Series', 'Movie') ? media : null

  const image = program.images.cover2x3.sourceEncoded

  // NOTE(hover-trailer): We wish to play the trailer with a 1 second delay on card hover. To avoid performance regression,
  // we wait 500ms until we render the video element and start the loading of the video. Then we make the video element
  // wait 500ms before playing the trailer.
  const { onMouseLeave, onMouseEnter, isHovered } = useHovered({
    delay: 500,
    enable: shouldShowHoverContent,
  })

  const hasTrailer = program?.trailers?.mp4 || program?.trailers?.webm
  const isUpsell = isUpsellMedia(program)

  const handleClick = () => {
    if (onCardClick) {
      onCardClick(program)
    }
  }

  if (!isTypeInUnion(program, 'Series', 'Movie')) return null

  return (
    <AssetTracking content_media_id={id} upsell={isUpsell ? { item: 'card', tier: program.upsell?.tierId } : null}>
      {({ trackOnAssetClick }) => (
        <CardContainer
          href={paths.program.urlString({ id, slug })}
          onMouseEnter={() => {
            onMouseEnter()
            prefetch()
          }}
          onMouseLeave={onMouseLeave}
          onClick={() => {
            trackOnAssetClick()
            handleClick()
          }}
        >
          <CardImageContainer nwHoverEffect="small">
            <CardLabelContainer>
              <LabelFactory media={program} nwPlacement="corner" showOnlyUpsellLabel={showOnlyUpsellLabel} />
            </CardLabelContainer>

            <FadeInImage
              alt={program.title}
              width={350}
              height={525}
              source={image}
              backgroundColorHex={program?.images?.cover2x3.meta?.muteBgColor?.hex}
            />

            <PosterCardHoverContent>
              <HoverCardTrailerContainer>
                {isHovered && hasTrailer && (
                  <CardVideoTrailer
                    playDelay={500} // See NOTE(hover-trailer).
                    video16x9mp4={program.trailers?.mp4}
                    video16x9webm={program.trailers?.webm}
                  />
                )}
              </HoverCardTrailerContainer>
              <HoverCardMetaContainer backgroundColor={program?.images?.cover2x3.meta?.muteBgColor?.hex}>
                <Stack nwGap={2}>
                  <StandardMetaPropertyList compact {...program} />
                  <TypographyText nwColor="primary" nwVariant="body3Strong">
                    <TruncateLines lines={2}>{program.title}</TruncateLines>
                  </TypographyText>
                  <TruncateLines lines={5}>
                    <TypographyText nwColor="primary" nwVariant="smallText">
                      {extendedProgram?.synopsis?.brief}
                    </TypographyText>
                  </TruncateLines>
                </Stack>
              </HoverCardMetaContainer>
            </PosterCardHoverContent>

            <CardButtonContainer>
              <FavoriteButton mediaId={program.id} mediaType={program.__typename} />
              {isHovered && hasTrailer && <MuteTrailersButton />}
            </CardButtonContainer>
          </CardImageContainer>
        </CardContainer>
      )}
    </AssetTracking>
  )
}
