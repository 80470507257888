import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { Banner } from '@/components/banner'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { paths } from '@/helpers/paths'

export const ChannelUpgradeBanner = () => {
  const t = useTranslations()
  const { isLoggedIn } = useAuthState()
  const router = useRouter()
  return (
    <Banner
      text={t(isLoggedIn ? 'channels__upgrade_desc_logged_in' : 'channels__upgrade_desc_logged_out')}
      href={paths.packages.url({
        return: router.asPath,
      })}
    />
  )
}
