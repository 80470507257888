import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { useIsClientSide } from '@nordic-web/utils/hooks/use-is-client-side'
import { nonNullable } from '@nordic-web/utils/typescript'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { useAccountApi } from '@/features/auth/hooks/use-account-api'
import { AuthModalPaths } from '@/features/auth/types'
import { filterChildContent } from '@/features/profiles/helpers/filter-child-content'
import { useUserQuery } from '@/features/profiles/hooks/use-user-query'
import { SPORTPAGE_ID, paths } from '@/helpers/paths'
import { useMatchMedia } from '@/hooks/use-match-media'
import { MAX_PROFILES } from '@/layouts/header/constants'
import { mediaTabletHeader } from '@/layouts/header/style'
import { STARTPAGE_ID } from '@/pages/index.page'
import { CHANNELPAGE_ID } from '@/pages/kanaler/[[...params]].page'
import { NEWSPAGE_ID } from '@/pages/nyheter/index.page'

export enum MainMenuItemIds {
  HOME = 'home',
  NEWS = 'news',
  CATEGORIES = 'categories',
  CHANNELS = 'channels',
  PACKAGES = 'packages',
  SPORT = 'sport',
  MYLIST = 'mylist',
  FAQ = 'faq',
}

export function useNoHamburgerMenu() {
  const noHamburgerMenuQuery = mediaTabletHeader({})[1] as string

  return useMatchMedia(noHamburgerMenuQuery)
}

export function useMainMenuItems() {
  const { isLoggedIn } = useAuthState()
  const isClientSide = useIsClientSide()
  const router = useRouter()
  const t = useTranslations()
  const { isChildProfile } = useUserQuery()

  return filterChildContent(
    [
      {
        id: MainMenuItemIds.HOME,
        text: t('main_menu__home'),
        active: paths.start.isActive(router),
        route: paths.start.urlString(),
        pagePrefetchId: STARTPAGE_ID,
        shouldShowInChildProfile: true,
      },
      {
        id: MainMenuItemIds.NEWS,
        text: t('main_menu__news'),
        active: paths.news.isActive(router),
        route: paths.news.urlString(),
        pagePrefetchId: NEWSPAGE_ID,
      },
      {
        id: MainMenuItemIds.CATEGORIES,
        text: t('main_menu__categories'),
        active: paths.categories.isActive(router) || paths.page.isActive(router),
        route: paths.categories.urlString(),
        shouldShowInChildProfile: true,
      },
      {
        id: MainMenuItemIds.CHANNELS,
        text: t('main_menu__channels'),
        active: paths.channels.isActive(router),
        route: paths.channels.urlString(),
        pagePrefetchId: CHANNELPAGE_ID,
      },
      {
        id: MainMenuItemIds.SPORT,
        text: t('main_menu__sport'),
        active: paths.sport.isActive(router),
        route: paths.sport.urlString(),
        pagePrefetchId: SPORTPAGE_ID,
      },
      isClientSide && isLoggedIn
        ? {
            id: MainMenuItemIds.MYLIST,
            text: t('main_menu__my_list'),
            active: paths.myList.isActive(router),
            route: paths.myList.urlString(),
            shouldShowInChildProfile: true,
          }
        : null,
      {
        id: MainMenuItemIds.PACKAGES,
        text: t('main_menu__packages'),
        active: paths.packages.isActive(router),
        route: paths.packages.urlString(),
      },
      {
        id: MainMenuItemIds.FAQ,
        text: t('main_menu__faq'),
        active: paths.faq.isActive(router),
        route: paths.faq.urlString(),
      },
    ].filter(nonNullable),
    isChildProfile
  )
}

export const useSubMenuItems = () => {
  const isClientSide = useIsClientSide()
  const { isLoggedIn } = useAuthState()
  const { logoutMutation } = useAccountApi()
  const t = useTranslations()
  const router = useRouter()
  const { dispatch } = useAuthModal()
  const { data, isChildProfile } = useUserQuery()
  const profiles = data?.user?.profiles || []
  const shouldShowProfiles = profiles.length <= MAX_PROFILES

  if (!isLoggedIn && isClientSide) {
    return [
      {
        id: 'login',
        text: t('general__log_in'),
        onClick: () => dispatch({ type: 'show-auth-modal', path: AuthModalPaths.LOGIN }),
      },
    ]
  }

  return filterChildContent(
    [
      {
        id: 'change-profiles',
        text: shouldShowProfiles ? t('main_menu__handle_profiles') : t('main_menu__change_profile'),
        onClick: () =>
          router.push(
            paths.selectProfile.urlString({
              return: router.asPath,
            })
          ),
        shouldShowInChildProfile: true,
      },
      {
        id: 'settings',
        text: t('main_menu__settings'),
        onClick: () => router.push(paths.settings.urlString()),
      },
      {
        id: 'logout',
        text: t('main_menu__log_out'),
        onClick: () => logoutMutation.mutate(),
        shouldShowInChildProfile: true,
      },
    ],
    isChildProfile
  )
}
