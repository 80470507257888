import type { ElementType } from 'react'
import type { CSSObject } from 'styled-components'
import styled from 'styled-components'
import type { Theme } from '@nordic-web/ui-theme'

export type TextColors = keyof Theme['color']['text']

export type TypographyTextProps = {
  nwVariant: keyof Theme['typography']
  nwTextAlign?: CSSObject['textAlign']
  nwColor?: TextColors
  as?: ElementType
}

export const TypographyText = styled.div<TypographyTextProps>(({ nwVariant, nwTextAlign, nwColor, theme }) => {
  return {
    ...theme.typography[nwVariant],
    textAlign: nwTextAlign,
    ...(nwColor ? { color: theme.color.text[nwColor] } : {}),
  }
})
