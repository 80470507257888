import styled from 'styled-components'
import type { Package } from '@nordic-web/rest-codegen/generated/subscription'
import { CardMainText, CardTertiaryText, Icon, Stack } from '@nordic-web/ui-components'

const FeatureRow = styled.div(({ theme }) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  gap: theme.space(2),
  justifyContent: 'left',
  alignItems: 'center',
}))

type FeatureListProps = Pick<Package, 'features' | 'disabledFeatures'>

export const PackageFeatureList = ({ features, disabledFeatures }: FeatureListProps) => {
  return (
    <Stack nwGap={2}>
      {features.map((feature, i) => (
        <FeatureRow key={i}>
          <Icon nwVariant="check" />
          <CardMainText>{feature}</CardMainText>
        </FeatureRow>
      ))}
      {disabledFeatures?.map((feature, i) => (
        <FeatureRow key={i}>
          <Icon nwVariant="minus" nwColor="tertiary" />
          <CardTertiaryText>{feature}</CardTertiaryText>
        </FeatureRow>
      ))}
    </Stack>
  )
}
