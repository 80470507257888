import type { FC, PropsWithChildren } from 'react'
import { Fragment } from 'react'
import { Stack } from '@nordic-web/ui-components'
import { ConditionalWrapper } from '@/components/_base/conditional-wrapper'
import { MaxWidthContainerMd } from '@/components/max-width-container/max-width-container-md'
import { PageMargin } from '@/components/scaffolding/page-margin'

type PageContainerProps = {
  verticalCenter?: boolean
  hidePageMargin?: boolean
}

export const PageContainer: FC<PropsWithChildren<PageContainerProps>> = ({
  children,
  verticalCenter = false,
  hidePageMargin = false,
}) => {
  const Wrapper = hidePageMargin ? Fragment : PageMargin

  return (
    <ConditionalWrapper
      condition={verticalCenter}
      wrapper={(children) => (
        <Stack nwJustifyContent="center" nwMinHeight="100vh">
          {children}
        </Stack>
      )}
    >
      <Wrapper>
        <Stack nwAlignItems="center">
          <MaxWidthContainerMd>{children}</MaxWidthContainerMd>
        </Stack>
      </Wrapper>
    </ConditionalWrapper>
  )
}
