import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import type { Conversions } from '@nordic-web/rest-codegen/generated/subscription'
import { type PackageProductData } from '@nordic-web/rest-codegen/generated/subscription'
import { useCheckoutPage } from '@/features/checkout/hooks/use-checkout-page'

export type CheckoutState = {
  hasAcceptedTerms: boolean
  setHasAcceptedTerms: (arg0: boolean) => void
  isCheckingOutWithBinding: boolean
  toggleBinding: (arg0: boolean) => void
  isWithAds: boolean
  setWithAds: (arg0: boolean) => void
  productDetails?: PackageProductData
  resetCheckoutState: () => void
  conversionType?: Conversions
}

export const CheckoutStateContext = createContext<CheckoutState | undefined>(undefined)

export const CheckoutStateProvider = ({ children }: PropsWithChildren) => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
  const [isCheckingOutWithBinding, toggleBinding] = useState(false)
  const [isWithAds, setWithAds] = useState(false)
  const { data: checkoutPageData } = useCheckoutPage()

  const productDetails = useMemo(() => {
    if (!checkoutPageData) return
    if (isCheckingOutWithBinding) {
      return checkoutPageData.packageData.defaultBindingProduct
    }
    return checkoutPageData.packageData.defaultProduct
  }, [checkoutPageData, isCheckingOutWithBinding])

  const resetCheckoutState = useCallback(() => {
    setHasAcceptedTerms(false)
    toggleBinding(false)
    setWithAds(false)
  }, [])

  return (
    <CheckoutStateContext.Provider
      value={{
        hasAcceptedTerms,
        setHasAcceptedTerms,
        isCheckingOutWithBinding,
        toggleBinding,
        isWithAds,
        setWithAds,
        productDetails,
        resetCheckoutState,
        conversionType: checkoutPageData?.conversion,
      }}
    >
      {children}
    </CheckoutStateContext.Provider>
  )
}

export const useCheckoutState = () => {
  const state = useContext(CheckoutStateContext)

  if (typeof state === 'undefined') {
    throw new Error('useCheckoutState must be used within a CheckoutStateContext')
  }

  return state
}
