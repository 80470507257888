import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import { Toggle } from '@nordic-web/ui-components'
import { useCheckoutState } from '@/features/checkout/context/checkout-state-context'

const BindingToggleContainer = styled.div({
  width: '100%',
  maxWidth: '430px',
  display: 'flex',
  justifyContent: 'center',
})

type BindingToggleProps = {
  disabled?: boolean
}

export const BindingToggle = ({ disabled = false }: BindingToggleProps) => {
  const { isCheckingOutWithBinding, toggleBinding } = useCheckoutState()
  const t = useTranslations()
  const toggleTexts = {
    headToggleOff: t('subscription__binding_toggle__off'),
    headToggleOn: disabled ? t('subscription__binding_toggle__disabled__on') : t('subscription__binding_toggle__on'),
  }
  return (
    <BindingToggleContainer>
      <Toggle value={isCheckingOutWithBinding} onToggle={toggleBinding} disabled={disabled} texts={toggleTexts} />
    </BindingToggleContainer>
  )
}
