import type { FC } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { Banner } from '@/components/banner'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { paths } from '@/helpers/paths'

type MediaUpgradeBannerProps = {
  title: string
  tierId: string
  tierName: string
  sticky: boolean
}

export const MediaUpgradeBanner: FC<MediaUpgradeBannerProps> = ({ title, tierId, tierName, sticky }) => {
  const t = useTranslations()
  const { isLoggedIn } = useAuthState()
  const router = useRouter()
  return (
    <Banner
      sticky={sticky}
      text={t(isLoggedIn ? 'cdp__upsell_banner_logged_in' : 'cdp__upsell_banner_logged_out', {
        title,
        tierName,
      })}
      href={paths.packages.url({
        tierId,
        return: router.asPath,
      })}
      tierId={tierId}
    />
  )
}
