import { useUserQuery as useUserQueryInternal } from '@nordic-web/gql'
import { useAuthState } from '@/features/auth/context/auth-state-context'

export const useUserQuery = () => {
  const { hasValidAccessToken } = useAuthState()
  const { data, loading: isLoading } = useUserQueryInternal({
    ssr: false,
    skip: !hasValidAccessToken,
  })

  const isChildProfile = !!data?.user.activeProfile?.isChild

  return { data, isLoading, isChildProfile }
}
