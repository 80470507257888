import type { ReactNode } from 'react'
import styled from 'styled-components'
import { desktop, tablet } from '@nordic-web/ui-styles'
import { Icon, type IconVariant } from '../../icon/icon/icon'
import { TypographyText } from '../../typography/typography-text/typography-text'

type AlertLevel = 'error' | 'warning' | 'neutral'

const Root = styled.div<{
  nwVariant: AlertLevel
}>(({ nwVariant, theme }) => {
  const isNeutral = nwVariant === 'neutral'
  return {
    display: 'flex',
    backgroundColor: isNeutral
      ? 'transparent'
      : nwVariant === 'error'
        ? theme.color.alert.critical
        : theme.color.alert.alert,

    color: isNeutral ? theme.color.alert.messageInverted : theme.color.alert.message,
    padding: theme.space(4),
    gap: theme.space(4),
    borderRadius: theme.radii.border_radius_small,
    [tablet]: {
      paddingLeft: theme.space(10),
      gap: theme.space(5),
    },
    [desktop]: {
      paddingLeft: theme.space(16),
    },
  }
})

const IconContainer = styled.div({
  display: 'flex',
  width: 24,
  height: 24,
  [desktop]: {
    width: 32,
    height: 32,
  },
})

const Content = styled(TypographyText)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignSelf: 'center',
})

const Close = styled(IconContainer)({
  appearance: 'none',
  display: 'flex',
  border: 0,
  padding: 0,
  backgroundColor: 'transparent',
  width: 24,
  height: 24,
  [desktop]: {
    width: 32,
    height: 32,
  },
})

type AlertProps = {
  icon?: IconVariant
  children?: ReactNode
  nwVariant?: AlertLevel
  closeable?: boolean
  onClose?: React.MouseEventHandler<HTMLButtonElement>
}

const icons: Record<string, IconVariant> = {
  error: 'alert-warning-outlined',
  warning: 'alert-headsup-filled',
}

/**
 * Display an alert, with an optional close button. Be sure to wrap this in a live region if it is
 * dynamically added to the after page load: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
 */
export const NotificationAlert = ({ icon, children, nwVariant = 'error', closeable, onClose }: AlertProps) => {
  const currentIconVariant = icon ?? icons[nwVariant]

  return (
    <Root nwVariant={nwVariant}>
      {currentIconVariant && (
        <IconContainer>
          <Icon nwVariant={currentIconVariant} nwUnset />
        </IconContainer>
      )}
      <Content nwVariant="body3">{children}</Content>
      {closeable && (
        <Close as="button" onClick={onClose}>
          <Icon nwVariant="close-x" />
        </Close>
      )}
    </Root>
  )
}
