import type { FC } from 'react'
import { useTranslations } from 'next-intl'
import type { ChannelFieldsFragment } from '@nordic-web/gql'
import { ChannelType } from '@nordic-web/gql'
import { Stack, TypographyText } from '@nordic-web/ui-components'

type MissingEpgMessageProps = {
  channel: ChannelFieldsFragment
}

export const MissingEpgMessage: FC<MissingEpgMessageProps> = ({ channel }) => {
  const t = useTranslations()
  if (channel.type === ChannelType.Standard) {
    return (
      <TypographyText nwColor="primary" nwVariant="body3">
        {t('channels__broadcast_pause')}
      </TypographyText>
    )
  }

  return (
    <Stack nwGap={1}>
      <TypographyText nwColor="primary" nwVariant="body3">
        {channel.tagline ?? channel.title}
      </TypographyText>
      <TypographyText nwColor="secondary" nwVariant="body4">
        {channel.description}
      </TypographyText>
    </Stack>
  )
}
