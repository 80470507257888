import type { PropsWithChildren } from 'react'
import { TypographyText } from '@nordic-web/ui-components'

export const CheckoutHeader = ({ children }: PropsWithChildren) => {
  return (
    <TypographyText nwVariant="title1" nwTextAlign="center">
      {children}
    </TypographyText>
  )
}
