import { isClientSide } from '@nordic-web/utils/misc/detect-side'
import { trackDevEvent } from '@/tracking/track-dev-event'

let serverClientTimeDiff = 0

const fetchTimeDiff = async () => {
  try {
    const response = await fetch('https://time.akamai.com/?iso')
    if (!response.ok) {
      throw new Error('A network error occured when fetching server time')
    }
    const serverTimeISO = await response.text()
    const serverTime = new Date(serverTimeISO)
    serverClientTimeDiff = serverTime.getTime() - Date.now()

    // Lets keep this for a while to see what kind of devices that actually has a diff of more than 30 seconds
    if (Math.abs(serverClientTimeDiff) > 30_000) {
      trackDevEvent({
        label1: 'statistics',
        label2: 'ServerTimeDiff',
        label3: serverClientTimeDiff,
      })
    }
  } catch (error) {
    console.error('Error fetching server time:', error)
    throw error
  }
}

if (isClientSide) {
  fetchTimeDiff()
}

// The history behind this is that we dont want to load live event countdowns
// before the event has started, and some devices seem to have the wrong time set
export const getServerTime = () => {
  return new Date(Date.now() + serverClientTimeDiff)
}
