import styled from 'styled-components'

/**
 * Base styles for form input elements. Not meant to be used directly in your code.
 */
export const FormInputBase = styled.input(({ theme }) => ({
  background: theme.color.form.default,
  borderRadius: theme.radii.border_radius_small,
  border: `1px solid transparent`,
  color: theme.color.text.primary,
  fontFamily: theme.fonts.body,

  '&:autofill, &:autofill:hover, &:autofill:focus': {
    // we are unable to override background color, so make it transition really slowly
    transition: 'background-color 5000s',
    // unset the box shadow
    boxShadow: 'none',
    // use same text color as input
    WebkitTextFillColor: theme.color.text.primary,
    // use same caret color (vertical blinky text indicator) as input text
    caretColor: theme.color.text.primary,
  },

  '&:hover, &:focus': {
    borderColor: theme.color.form.borderFocus,
    outline: 'none',
  },

  '&[aria-invalid="true"]': {
    borderColor: theme.color.border.error,
  },
}))
