import { useMutation } from '@tanstack/react-query'
import type { ApiError } from '@nordic-web/rest-codegen/generated/subscription'
import * as SubscriptionService from '@nordic-web/rest-codegen/generated/subscription'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { parseJwt } from '@nordic-web/utils/authentication/token'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import { nextConfig } from '@/helpers/env'

SubscriptionService.OpenAPI.BASE = nextConfig.string('SUBSCRIPTION_API') + '/v1'

export const useIap = () => {
  const { accessToken } = useAuthState()
  const token = formatAuthorizationHeader(accessToken)

  const deleteIapSubscription = useMutation({
    mutationFn: () =>
      SubscriptionService.deleteIapSubscriptionForUser({
        authorization: token,
      }),
    onError: (error: ApiError) => console.error(`deleteIapSubscription error ${error}`),
  })

  const payload = parseJwt(token)

  /** @TODO fix AccessTokenPayload type **/
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const hasIap = payload && payload['entitlement_source']?.toLowerCase() === 'iap'

  return {
    hasIap,
    deleteIapSubscription,
  }
}
