import Link from 'next/link'
import styled from 'styled-components'
import type { TextColors } from '../typography-text/typography-text'

type TypographyLinkProps = {
  nwColor?: TextColors
}

/**
 * TODO: Add focus states to this component.
 */
export const TypographyLink = styled(Link)<TypographyLinkProps>(({ theme, nwColor = 'primary' }) => ({
  color: theme.color.text[nwColor],
  textDecoration: 'underline',
  cursor: 'pointer',
  fontWeight: theme.fontWeightBold,
  '&:hover': {
    color: theme.color.text.highlight,
  },
}))
