import type { ReactNode } from 'react'
import React from 'react'
import { createContext, useContext } from 'react'
import { getPageName } from '@/helpers/get-page-name'
import { dataLayerPush } from '@/tracking/tracking'

type CancelFlowContextProps = {
  productId?: string
  productGroupId?: string
  downgradeClick: () => void
  cancelClick: () => void
  upgradeClick: () => void
  reactivateClick: () => void
  settingsClick: () => void
  confirmDowngrade: (productGroupId: string, productId: string) => void
  cancel: (productGroupId: string, productId: string) => void
  confirmUpgrade: (productGroupId: string, productId: string) => void
  confirmReactivate: (productGroupId: string, productId: string) => void
  confirmDiscount: (productGroupId: string, productId: string) => void
  cancelAnswer: (productGroupId: string, option: string, freeText: string) => void
  cancelConfirm: (productGroupId: string) => void
  discountConfirm: (productGroupId: string, productId: string) => void
  trackPage: (purchaseFlow: string, pageName?: string) => void
}

enum CancelFlowEvent {
  Downgrade = 'downgrade_package',
  Cancel = 'cancel_package',
  Upgrade = 'upgrade_package',
  Reactivate = 'reactivate_package',
  Change = 'change_package',
  ConfirmDowngrade = 'confirm_downgrade_package',
  ConfirmCancel = 'confirm_cancel_package',
  ConfirmUpgrade = 'confirm_upgrade_package',
  ConfirmReactivate = 'confirm_reactivate_package',
  ConfirmDiscount = 'confirm_discount_package',
  CancelAnswer = 'cancellation_answers',
  ConfirmCancelFinal = 'final_confirm_cancel_package',
  AcceptOffer = 'discount_package',
}

const CancelFlowContext = createContext<CancelFlowContextProps | undefined>(undefined)

export const CancelFlowTracking = ({ children }: { children?: ReactNode | undefined }) => {
  const page_name = getPageName()

  const downgradeClick = () => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.Downgrade,
    })
  }

  const cancelClick = () => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.Cancel,
    })
  }

  const upgradeClick = () => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.Upgrade,
    })
  }

  const reactivateClick = () => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.Reactivate,
    })
  }

  const settingsClick = () => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.Change,
    })
  }

  const confirmDowngrade = (productGroupId: string, productId: string) => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.ConfirmDowngrade,
      purchase_flow: 'verify',
      purchase_flow_product_group_id: productGroupId,
      purchase_flow_product_id: productId,
    })
  }

  const cancel = (productGroupId: string, productId: string) => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.ConfirmCancel,
      purchase_flow: 'verify',
      purchase_flow_product_group_id: productGroupId,
      purchase_flow_product_id: productId,
    })
  }

  const confirmUpgrade = (productGroupId: string, productId: string) => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.ConfirmUpgrade,
      purchase_flow: 'verify',
      purchase_flow_product_group_id: productGroupId,
      purchase_flow_product_id: productId,
    })
  }

  const confirmReactivate = (productGroupId: string, productId: string) => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.ConfirmReactivate,
      purchase_flow: 'verify',
      purchase_flow_product_group_id: productGroupId,
      purchase_flow_product_id: productId,
    })
  }

  const confirmDiscount = (productGroupId: string, productId: string) => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.ConfirmDiscount,
      purchase_flow_product_group_id: productGroupId,
      purchase_flow_product_id: productId,
    })
  }

  const cancelAnswer = (productGroupId: string, option: string, freeText: string) => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.CancelAnswer,
      purchase_flow: 'cancel',
      purchase_flow_product_group_id: productGroupId,
      answer_option: option,
      answer_free_text: freeText,
    })
  }

  const cancelConfirm = (productGroupId: string) => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.ConfirmCancelFinal,
      purchase_flow: 'cancel',
      purchase_flow_product_group_id: productGroupId,
    })
  }

  const discountConfirm = (productGroupId: string, productId: string) => {
    dataLayerPush({
      page_name,
      event: CancelFlowEvent.AcceptOffer,
      purchase_flow: 'discount',
      purchase_flow_product_group_id: productGroupId,
      purchase_flow_product_id: productId,
    })
  }

  const trackPage = (pageName: string) => {
    dataLayerPush({
      event: 'page',
      page_name: pageName,
      purchase_flow: 'cancel',
    })
  }

  return (
    <CancelFlowContext.Provider
      value={{
        reactivateClick,
        downgradeClick,
        cancelClick,
        upgradeClick,
        settingsClick,
        confirmDowngrade,
        cancel,
        confirmUpgrade,
        confirmReactivate,
        confirmDiscount,
        cancelAnswer,
        cancelConfirm,
        discountConfirm,
        trackPage,
      }}
    >
      {children}
    </CancelFlowContext.Provider>
  )
}

export const useCancelFlowTracking = () => {
  return useContext(CancelFlowContext)
}
